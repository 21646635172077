import React from "react";
import { Helmet } from "react-helmet";
import { useUser } from "../ContextApi/userContextApi";

const Footer = () => {
  const { user } = useUser();

  return (
    <footer className="bg-black text-white border-t border-[#62f062] py-8 md:py-12">
      <Helmet>
        <title>ToolzStream - Your Source for Premium Subscriptions</title>
        <meta
          name="description"
          content="Explore ToolzStream for premium subscription plans, features, and more. Contact us for support or learn about our terms and conditions."
        />
        <meta
          name="keywords"
          content="ToolzStream, premium subscriptions, 24x7 support, refund policy, privacy policy, terms and conditions"
        />
        <meta
          property="og:title"
          content="ToolzStream - Your Source for Premium Subscriptions"
        />
        <meta
          property="og:description"
          content="Explore ToolzStream for premium subscription plans, features, and more. Contact us for support or learn about our terms and conditions."
        />
        <meta property="og:url" content="https://toolzstream.me/" />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-center md:text-left">
          <div className="flex flex-col">
            <h2 className="text-lg font-semibold mb-4 text-[#62f062]">Plans</h2>
            <ul className="text-sm space-y-2">
              <li>
                <a
                  href="/customer/products/65e48c2c6a01b551bc21d128"
                  className="hover:underline"
                >
                  Lite Plan
                </a>
              </li>
              <li>
                <a
                  href="/customer/products/65e48c2c6a01b821bc21d128"
                  className="hover:underline"
                >
                  Pro Plan
                </a>
              </li>
              <li>
                <a
                  href="/customer/products/65e48c2c6a01b231bc21d128"
                  className="hover:underline"
                >
                  Advanced Plan
                </a>
              </li>
              <li>
                <a
                  href="/customer/products/65e48c2c6a91b321bc41d998"
                  className="hover:underline"
                >
                  Designer's Pack
                </a>
              </li>
            </ul>
          </div>

          <div className="flex flex-col">
            <h2 className="text-lg font-semibold mb-4 text-[#62f062]">
              Features
            </h2>
            <ul className="text-sm space-y-2">
              <li>
                <a
                  href="mailto:support@toolzstream.me"
                  className="hover:underline"
                >
                  24x7 Support
                </a>
              </li>
              <li>Instant Access</li>
            </ul>
          </div>

          <div className="flex flex-col">
            <h2 className="text-lg font-semibold mb-4 text-[#62f062]">More</h2>
            <ul className="text-sm space-y-2">
              <li>
                <a
                  href="https://discord.gg/RkQRZXsV"
                  target="_blank"
                  className="hover:underline"
                  rel="noopener noreferrer"
                >
                  Contact Us
                </a>
              </li>
              <li>
                <a href="/privacy_policy" className="hover:underline">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>

          <div className="flex flex-col">
            <h2 className="text-lg font-semibold mb-4 text-[#62f062]">
              Get Started
            </h2>
            <ul className="text-sm space-y-2">
              <li>
                <a href="/about" className="hover:underline">
                  About Us
                </a>
              </li>
              {!user ? (
                <>
                  <li>
                    <a href="/login" className="hover:underline">
                      Login
                    </a>
                  </li>
                  <li>
                    <a href="/register" className="hover:underline">
                      Sign-up
                    </a>
                  </li>
                </>
              ) : null}
              <li>
                <a href="/privacy_policy" className="hover:underline">
                  Refund Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mt-3 mx-auto flex flex-col md:flex-row justify-between items-center pt-8 border-t border-gray-700 px-4 sm:px-6 lg:px-8">
        <a
          href="#"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm text-gray-400 mb-4 md:mb-0 hover:underline"
        >
          &copy; All rights reserved ToolzStream.me
        </a>

        <div className="flex flex-wrap justify-center space-x-4">
          <a
            className="text-sm text-gray-400 hover:underline"
            href="/term-and-conditions"
          >
            Terms & Conditions
          </a>
          <a
            className="text-sm text-gray-400 hover:underline"
            href="/privacy-policy"
          >
            Privacy
          </a>
          <a
            className="text-sm text-gray-400 hover:underline"
            href="/disclaimer"
          >
            Disclaimer
          </a>
          <a className="text-sm text-gray-400 hover:underline" href="/contact">
            Contact
          </a>
          <a className="text-sm text-gray-400 hover:underline" href="/help">
            Help
          </a>
        </div>

        <p className="text-sm text-gray-400 text-center md:text-right">
          Made with <span className="text-red-500">❤</span> by{" "}
          <a
            href="https://discord.gg/RkQRZXsV"
            target="_blank"
            className="text-blue-500 hover:underline"
            rel="noopener noreferrer"
          >
            ToolzStream
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
