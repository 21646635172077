import React, { useEffect, useState } from "react";
import { AiFillDiscord } from "react-icons/ai";
import videoSrc from "../Assest/help.mp4";

const Help = () => {
  const [openSections, setOpenSections] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, message } = formData;
    const mailtoLink = `mailto:support@toolzstream.me?subject=Contact from ${name}&body=Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;
    window.location.href = mailtoLink;
  };

  const toggleSection = (index) => {
    setOpenSections((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const questionsAndAnswers = [
    {
      question: "Product not working or showing a black screen?",
      answer:
        "If you encounter a black screen or the product is not working as expected, try the following steps:\n1. Refresh the page.\n2. Clear your browser cache.\n3. Ensure your browser is updated to the latest version.\n4. Disable any ad blockers or extensions that might interfere with the product.\nIf the issue persists, contact our support team at support@toolzstream.me.",
    },
    {
      question: "How can I save my Canva design permanently?",
      answer:
        "To save your Canva design permanently, download the design to your local storage. You can do this by clicking the 'Download' button in Canva and selecting your preferred file format. Make sure to back up your downloaded files regularly to avoid any data loss.",
    },
    {
      question: "Product isn't working and says login required?",
      answer:
        "If you encounter this issue, please wait for 1-2 hours as the admin will automatically update the product. You can also contact our support team at support@toolzstream.me for assistance.",
    },
    {
      question: "Unable to login or use the page?",
      answer:
        "This issue may occur if you have violated the website's policy. For assistance, please contact our support team at support@toolzstream.me.",
    },
    {
      question: "Plan isn't showing after purchase?",
      answer: "To resolve this issue, please log out and then log back in.",
    },
    {
      question: "Amount has been paid but no product is active?",
      answer:
        "To resolve this issue, please log out and then log back in Or For assistance, please contact our support team at support@toolzstream.me.",
    },
    // Add more questions and answers as needed
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="help">
      <br />
      <br />
      <div className="max-w-7xl mx-auto font-mono px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center">
          <h1 className="text-4xl sm:text-5xl font-extrabold text-green-400 mb-4">
            Help & Support
          </h1>
          <p className="text-lg text-gray-600 mb-8">
            Find answers to frequently asked questions and troubleshoot common
            issues.
          </p>
        </div>

        <div className="space-y-6">
          {questionsAndAnswers.map((qa, index) => (
            <div
              key={index}
              className="border border-gray-200 rounded-lg shadow-sm"
            >
              <button
                className="w-full px-4 py-2 text-left text-lg font-semibold text-gray-800 bg-gray-100 rounded-t-lg focus:outline-none"
                onClick={() => toggleSection(index)}
              >
                {qa.question}
              </button>
              {openSections.includes(index) && (
                <div className="px-4 py-2 bg-white">
                  <p className="text-gray-600 whitespace-pre-wrap">
                    {qa.answer}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="contact-us-form mt-12 bg-[#ffffff36] p-2 rounded-2xl flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 p-4">
            <video
              src={videoSrc}
              autoPlay
              muted
              loop
              className="rounded-2xl shadow-md w-full"
            />
          </div>
          <div className="md:w-1/2 p-4">
            <h2 className="text-3xl font-bold text-green-400 mb-6 text-center">
              Send Us a Message
            </h2>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-300"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-300"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-300"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  required
                ></textarea>
              </div>
              <div>
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-400 hover:bg-green-500"
                >
                  Send Message
                </button>
              </div>
            </form>
            <div className="mt-10 text-center">
              <a
                href="https://discord.gg/RkQRZXsV"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-400 hover:text-[#e24fb1] text-5xl transition duration-300"
              >
                <AiFillDiscord />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
