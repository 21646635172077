import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import Logo from "../Assest/svg/Live.png";

const CustomerReviews = () => {
  return (
    <div className="customer bg-black bg-opacity-80 py-16 w-[90%] mx-auto">
      <Helmet>
        <title>Customer Reviews - Toolzstream</title>
        <meta
          name="description"
          content="Read customer reviews about Toolzstream's exceptional service and affordable prices for premium tools. Discover why users recommend Toolzstream for access to top-notch digital tools."
        />
        <meta
          name="keywords"
          content="Toolzstream, customer reviews, premium tools, affordable prices, user testimonials"
        />
        <meta property="og:title" content="Customer Reviews - Toolzstream" />
        <meta
          property="og:description"
          content="Read customer reviews about Toolzstream's exceptional service and affordable prices for premium tools. Discover why users recommend Toolzstream for access to top-notch digital tools."
        />
        <meta
          property="og:url"
          content="https://toolzstream.me/customer/reviews"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={Logo} />
      </Helmet>
      <div className="customer-con max-w-7xl mx-auto relative z-10">
        <img
          src="images/515516d9-test-bg-shape.png"
          alt="Background shape"
          className="absolute bottom-0 left-36 z-0 filter blur-[10px]"
        />
        <h2 className="md:text-4xl text-[24px] font-bold font-mono text-green-500 mb-10 text-center">
          Customer Reviews
        </h2>
        <div className="cus-flex md:flex p-5 md:p-0 flex-row gap-8">
          <div className="cus-box-one w-full my-2 h-[100%] space-y-2 md:w-1/4 bg-white bg-opacity-80 p-6 rounded-lg">
            <p className="md:text-lg text-[13px] font-mono text-gray-700 mb-6">
              ToolzStream provides unparalleled service. I've acquired a
              comprehensive array of top-notch tools at remarkably affordable
              rates. I wholeheartedly endorse ToolzStream to anyone seeking
              access to premium tools at unbeatable prices.
            </p>
            <h4 className="md:text-lg text-[13px] font-mono font-semibold text-purple-900 mb-2">
              Ayush Negi
            </h4>
            <span className="text-yellow-500">
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
            </span>
          </div>

          <div className="cus-box-two w-full md:w-1/2 flex flex-col gap-6">
            <div className="cus-box-up bg-white bg-opacity-80 p-6 rounded-lg">
              <p className="md:text-lg text-[13px] font-mono text-gray-700 mb-6">
                Outstanding Customer Support! ToolzStream ensures all tools
                remain operational and promptly addresses any issues with
                updates. The remarkable selection of high-quality tools
                available at such competitive prices is truly exceptional. Keep
                up the great work!
              </p>
              <h4 className="md:text-lg text-[13px] font-mono font-semibold text-purple-900 mb-2">
                Priya
              </h4>
              <span className="text-yellow-500">
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
              </span>
            </div>

            <div className="cus-box-down bg-green-600 bg-opacity-80 p-6 rounded-lg">
              <p className="md:text-lg text-[13px] font-mono text-white mb-6">
                The service provided by ToolzStream was exceptional! The support
                team was both friendly and efficient. I would wholeheartedly
                recommend this service to others.
              </p>
              <h4 className="md:text-lg text-[13px] font-mono font-semibold text-white mb-2">
                Durga
              </h4>
              <span className="text-yellow-500">
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
              </span>
            </div>
          </div>

          <div className="cus-box-one my-2 w-full md:w-1/4 bg-white bg-opacity-80 p-6 rounded-lg">
            <p className="md:text-lg text-[13px] font-mono text-gray-700 mb-6">
              Opting for ToolzStream at such affordable rates has always proven
              to be a wise decision. I've been a loyal customer for 4 months
              now, and their service remains consistently excellent. I've never
              encountered any issues with them.
            </p>
            <h4 className="md:text-lg text-[13px] font-mono font-semibold text-purple-900 mb-2">
              Vinay Kumar
            </h4>
            <span className="text-yellow-500">
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerReviews;
