import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import StepImage1 from "../Assest/gudie.png";
import StepImage2 from "../Assest/activeplan.png";
import aboutImg1 from "../Assest/svg/1.gif";
import aboutImg2 from "../Assest/svg/2.gif";
// import Logo from "../Assest/svg/LogoGif.gif";
import Logo from "../Assest/svg/Gifimg.gif";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about bg-black text-white">
      <Helmet>
        <title>About Toolzstream - Premium OTT Plans</title>
        <meta
          name="description"
          content="Learn about Toolzstream, your destination for premium OTT plans and website accounts at affordable prices. Discover our mission, vision, and services."
        />
        <meta
          property="og:title"
          content="About Toolzstream - Premium OTT Plans"
        />
        <meta
          property="og:description"
          content="Learn about Toolzstream, your destination for premium OTT plans and website accounts at affordable prices. Discover our mission, vision, and services."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://toolzstream.me/about" />
        <meta property="og:image" content={aboutImg1} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="About Toolzstream - Premium OTT Plans"
        />
        <meta
          name="twitter:description"
          content="Learn about Toolzstream, your destination for premium OTT plans and website accounts at affordable prices. Discover our mission, vision, and services."
        />
        <meta name="twitter:image" content={aboutImg2} />
      </Helmet>

      <div className="max-w-7xl mx-auto mt-10 px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center">
          <h1 className="text-4xl sm:text-5xl font-extrabold text-green-400 mb-4">
            About Toolzstream
          </h1>
          <p className="text-lg text-gray-600 mb-8">
            Toolzstream offers premium OTT plans and website accounts at
            affordable prices. Our mission is to provide high-quality digital
            services to users worldwide.
          </p>
        </div>
        <div className="about-img">
          <img
            src={Logo}
            alt="Illustration 2"
            className="w-full rounded-lg shadow-lg"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-12">
          <div className="about-img">
            <img
              src={aboutImg1}
              alt="Illustration 1"
              className="w-full rounded-lg shadow-lg"
            />
          </div>
          <div className="about-img">
            <img
              src={aboutImg2}
              alt="Illustration 2"
              className="w-full rounded-lg shadow-lg"
            />
          </div>
        </div>

        <div className="mt-12">
          <h2 className="text-3xl sm:text-4xl font-bold text-green-400 mb-4 text-center">
            Why Choose Toolzstream?
          </h2>
          <ul className="list-disc list-inside text-gray-600">
            <li className="mb-4">
              <span className="font-bold text-white">Affordable Prices:</span>{" "}
              Get premium OTT plans at a fraction of the original cost.
            </li>
            <li className="mb-4">
              <span className="font-bold text-white">
                Wide Range of Services:
              </span>{" "}
              Choose from a variety of subscription plans including Netflix,
              Prime, and Combo Packs.
            </li>
            <li className="mb-4">
              <span className="font-bold text-white">
                User-Friendly Interface:
              </span>{" "}
              Our platform is designed to be easy to navigate, making your
              experience seamless and enjoyable.
            </li>
            <li className="mb-4">
              <span className="font-bold text-white">Secure Transactions:</span>{" "}
              We prioritize your security with encrypted payment gateways and
              secure data storage.
            </li>
            <li className="mb-4">
              <span className="font-bold text-white">
                24/7 Customer Support:
              </span>{" "}
              Our dedicated support team is always available to assist you with
              any issues or queries.
            </li>
            <li className="mb-4">
              <span className="font-bold text-white">Easy Access:</span> Manage
              your subscriptions and access your plans easily through our{" "}
              <a href="/customer/products" className="text-blue-600">
                Customer Products
              </a>{" "}
              page.
            </li>
          </ul>
        </div>

        <div className="mt-12">
          <h2 className="md:text-3xl text-[15px] font-bold font-mono text-[yellowgreen] mb-4 text-center">
            How to Use Toolzstream
          </h2>
          <div className="max-w-3xl mx-auto">
            {/* Step 1 visible only on mobile */}
            <div className="mb-12 flex flex-col md:flex-row items-center md:items-start border-yellowgreen border-2 rounded-lg p-4 block md:hidden">
              <div className="w-full pl-0 md:pl-8 mb-8 md:mb-0">
                <h3 className="text-xl font-semibold text-white mb-2">
                  Step 1: Download Kiwi Browser
                </h3>
                <p className="text-gray-400">
                  Download Kiwi Browser from the{" "}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.kiwibrowser.browser&pli=1"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-yellow-500 underline ml-1"
                  >
                    Google Play Store
                  </a>
                  . (Website only works on Kiwi Browser in mobile)
                </p>
              </div>
            </div>

            <div className="mb-12 flex flex-col md:flex-row items-center md:items-start border-yellowgreen border-2 rounded-lg p-4">
              <div className="w-full pl-0 md:pl-8 mb-8 md:mb-0">
                <h3 className="text-xl font-semibold text-white mb-2">
                  Step 2: Login or Register
                </h3>
                <p className="text-gray-400">
                  Log in or register on Toolzstream using your credentials.{" "}
                  <a
                    href="/login"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-yellow-500 underline ml-1"
                  >
                    Login/Register
                  </a>
                </p>
              </div>
            </div>

            <div className="mb-12 flex flex-col md:flex-row items-center md:items-start border-yellowgreen border-2 rounded-lg p-4">
              <div className="w-full md:w-2/3">
                <img
                  src={StepImage2}
                  alt="Access Your Plans"
                  className="mt-4 w-full rounded-lg shadow-lg"
                />
              </div>
              <div className="w-full md:w-1/3 pl-0 md:pl-8 mb-8 md:mb-0">
                <h3 className="text-xl font-semibold text-white mb-2">
                  Step 3: Purchase a Plan
                </h3>
                <p className="text-gray-400">
                  Browse and choose a subscription plan that suits your needs.{" "}
                  <a
                    href="/customer/products"
                    className="text-yellow-500 underline ml-1"
                  >
                    Browse Plans
                  </a>
                </p>
              </div>
            </div>

            <div className="mb-12 flex flex-col md:flex-row items-center md:items-start border-yellowgreen border-2 rounded-lg p-4">
              <div className="w-full md:w-2/3">
                <img
                  src={StepImage1}
                  alt="Download Extension"
                  className="mt-4 w-full rounded-lg shadow-lg"
                />
              </div>
              <div className="w-full md:w-1/3 pl-0 md:pl-8 mb-8 md:mb-0">
                <h3 className="text-xl font-semibold text-white mb-2">
                  Step 4: Download Extension
                </h3>
                <p className="text-gray-400">
                  Follow the steps on our{" "}
                  <a
                    href="/customer/guide"
                    className="text-yellow-500 underline ml-1"
                  >
                    Customer Guide
                  </a>{" "}
                  page to download and set up the Toolzstream extension.
                </p>
                <p className="text-gray-400 mt-4">
                  Watch this{" "}
                  <a
                    href="https://youtu.be/EtsauOIXHWI"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-yellow-500 underline ml-1"
                  >
                    YouTube video
                  </a>{" "}
                  for detailed instructions.
                </p>
              </div>
            </div>

            <div className="mb-12 flex flex-col md:flex-row items-center md:items-start border-yellowgreen border-2 rounded-lg p-4">
              <div className="w-full md:w-2/3">
                <img
                  src={StepImage2}
                  alt="Access Your Plans"
                  className="mt-4 w-full rounded-lg shadow-lg"
                />
              </div>
              <div className="w-full md:w-1/3 pl-0 md:pl-8 mb-8 md:mb-0">
                <h3 className="text-xl font-semibold text-white mb-2">
                  Step 5: Access Your Plans
                </h3>
                <p className="text-gray-400">
                  Go to the{" "}
                  <a
                    href="/customer/products"
                    className="text-yellow-500 underline ml-1"
                  >
                    Customer Products
                  </a>{" "}
                  page to see all your active and locked plans.
                </p>
                <p className="text-gray-400 mt-4">
                  For 24/7 chat support, join our{" "}
                  <a
                    href="https://discord.gg/RkQRZXsV"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-yellow-500 underline ml-1"
                  >
                    Discord community
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
