import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  AiOutlineWhatsApp,
  AiOutlineAliwangwang,
  AiFillDiscord,
  AiOutlineShoppingCart,
  AiOutlineAlert,
} from "react-icons/ai";
import { Link, useNavigate, useLocation } from "react-router-dom";
import img1 from "../Assest/1.png";
import img2 from "../Assest/2.png";
import img3 from "../Assest/3.png";
import logo from "../Assest/logo.png";
import { useUser } from "../ContextApi/userContextApi";
import { useCart } from "../ContextApi/CartContextProvider";
import { HiMenu, HiX } from "react-icons/hi";

const Header = () => {
  const { user, logoutUser } = useUser();
  const { clearCart } = useCart();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const { cartItems } = useCart();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLogout = () => {
    logoutUser();
    clearCart();
    localStorage.removeItem("userCookies");
    navigate("/login");
  };

  const cartItemCount = cartItems.length;

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  // Function to check if the current path matches the given path
  const isActive = (path) => location.pathname === path;

  return (
    <div className="z-50 fixed w-full">
      <Helmet>
        <title>Toolzstream - Streamlined Access to Tools</title>
        <meta
          name="description"
          content="Explore Toolzstream for streamlined access to tools and services. Sign in or sign up to access plans, products, and guides. Manage your subscription with ease."
        />
        <meta
          name="keywords"
          content="Toolzstream, access tools, plans, products, guides, subscription management"
        />
        <meta
          property="og:title"
          content="Toolzstream - Streamlined Access to Tools"
        />
        <meta
          property="og:description"
          content="Explore Toolzstream for streamlined access to tools and services. Sign in or sign up to access plans, products, and guides. Manage your subscription with ease."
        />
        <meta property="og:url" content="https://toolzstream.me/" />
        <meta property="og:type" content="website" />
      </Helmet>
      {/* Top bar with slogan */}
      <div className="bg-black border-b border-[#69f769] py-2">
        <div className="max-w-[95%] mx-auto flex justify-between items-center">
          <div className="flex items-center md:w-auto w-1/2 space-x-3">
            <img src={img1} className="md:h-12 h-10 rounded-lg" alt="Logo 1" />
            <div className="space-y-1">
              <img src={img2} className="md:h-8 h-3 rounded-2xl" alt="Logo 2" />
              <img src={img3} className="md:h-8 h-3 rounded-2xl" alt="Logo 3" />
            </div>

            <Link
              to="/"
              className="md:text-2xl text-[12px] font-thin uppercase text-[#6df76d] leading-tight"
            >
              Streamlined{" "}
              <span className="text-white font-semibold">access</span> to tools!
            </Link>
          </div>

          <ul className="flex items-center justify-center md:space-x-4 space-x-2 md:text-[25px] text-[23px]">
            <li>
              <a
                href="https://discord.gg/RkQRZXsV"
                target="_blank"
                className="text-white hover:text-[#e24fb1] transition duration-300"
              >
                <AiFillDiscord />
              </a>
            </li>

            <li>
              <Link
                to="/customer/guide"
                className="text-white hover:text-[#ff5ff2] transition duration-300"
              >
                <AiOutlineAliwangwang />
              </Link>
            </li>

            <li className="relative">
              <Link
                to="/notification"
                className="text-white hover:text-[#ff3d3d] transition duration-300"
              >
                <AiOutlineAlert className="animate-pulse" />
                <span className="absolute top-0 right-0 block h-2.5 w-2.5 bg-red-500 rounded-full border border-white"></span>
              </Link>
            </li>

            {user ? (
              <li>
                <button
                  onClick={handleLogout}
                  className="bg-transparent border border-white text-white font-semibold md:text-xl text-[13px] md:py-2 p-1 md:px-4 px-2 rounded-md transition-colors duration-300 hover:bg-white hover:text-black"
                >
                  SignOut
                </button>
              </li>
            ) : (
              <li>
                <Link
                  to="/login"
                  className="bg-transparent text-[#6df76d] font-semibold p-2 text-xl rounded-md shadow-md transition transform hover:translate-y-1 hover:shadow-lg inline-flex items-center justify-center hover:text-white hover:bg-[#3ce959]"
                >
                  SignIn
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
      {/* Main header with logo and navigation */}
      <nav className="w-full bg-transparent backdrop-filter backdrop-blur-lg bg-opacity-40">
        <div className="max-w-[85%] mx-auto flex justify-between items-center py-2">
          <div className="flex items-center space-x-3">
            {/* Logo */}
            <img src={logo} className="h-12 rounded-lg" alt="Logo" />

            {/* Site title */}
            <Link
              to="/"
              className="text-xl md:text-3xl font-bold uppercase text-[#6df76d] leading-tight"
            >
              Toolz<span className="text-white">Stream</span>
            </Link>
          </div>

          <div className=" display md:hidden flex justify-center items-center space-x-2">
            <Link to="/customer/cart" className=" relative text-2xl text-white">
              <AiOutlineShoppingCart />
              {cartItemCount > 0 && (
                <div className="absolute -top-1 -right-1 w-3 h-3 bg-green-500 rounded-full"></div>
              )}
            </Link>

            {/* Hamburger menu for mobile */}
            <div className="md:hidden relative">
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="text-white text-3xl z-50 relative"
              >
                {isMobileMenuOpen ? <HiX /> : <HiMenu />}
              </button>
            </div>
          </div>

          <ul className="hidden md:flex gap-6 bg-transparent backdrop-filter backdrop-blur-lg bg-opacity-40 p-2 rounded-xl">
            <li>
              <Link
                to="/"
                className={`text-white hover:text-[#32fa32] transition-transform transform hover:scale-105 duration-300 ${
                  isActive("/")
                    ? "border-b-2 border-[#32fa32] text-[#32fa32]"
                    : ""
                }`}
              >
                HOME
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className={`text-white hover:text-[#32fa32] transition-transform transform hover:scale-105 duration-300 ${
                  isActive("/about")
                    ? "border-b-2 border-[#32fa32] text-[#32fa32]"
                    : ""
                }`}
              >
                ABOUT
              </Link>
            </li>
            <li>
              <Link
                to="/customer/dashboard"
                className={`text-white hover:text-[#32fa32] transition-transform transform hover:scale-105 duration-300 ${
                  isActive("/customer/dashboard")
                    ? "border-b-2 border-[#32fa32] text-[#32fa32]"
                    : ""
                }`}
              >
                DASHBOARD
              </Link>
            </li>
            <li>
              <Link
                to="/customer/products"
                className={`text-white hover:text-[#32fa32] transition-transform transform hover:scale-105 duration-300 ${
                  isActive("/customer/products")
                    ? "border-b-2 border-[#32fa32] text-[#32fa32]"
                    : ""
                }`}
              >
                PLANS
              </Link>
            </li>
            <li>
              <Link
                to="/customer/guide"
                className={`text-white hover:text-[#32fa32] transition-transform transform hover:scale-105 duration-300 ${
                  isActive("/customer/guide")
                    ? "border-b-2 border-[#32fa32] text-[#32fa32]"
                    : ""
                }`}
              >
                GUIDE
              </Link>
            </li>
            <li>
              <Link
                to="/how-to-use"
                className={`text-white hover:text-[#32fa32] transition-transform transform hover:scale-105 duration-300 ${
                  isActive("/how-to-use")
                    ? "border-b-2 border-[#32fa32] text-[#32fa32]"
                    : ""
                }`}
              >
                HOW TO USE?
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={`text-white hover:text-[#32fa32] transition-transform transform hover:scale-105 duration-300 ${
                  isActive("/contact")
                    ? "border-b-2 border-[#32fa32] text-[#32fa32]"
                    : ""
                }`}
              >
                CONTACT
              </Link>
            </li>
            <li>
              <Link
                to="/customer/cart"
                className={`text-white hover:text-[#32fa32] transition-transform transform hover:scale-105 duration-300 ${
                  isActive("/customer/cart")
                    ? "border-b-2 border-[#32fa32] text-[#32fa32]"
                    : ""
                }`}
              >
                CART
                {cartItemCount > 0 && (
                  <span className="bg-green-500 text-black text-sm font-semibold rounded-full w-4 h-4 flex items-center justify-center absolute -top-1 -right-1">
                    {cartItemCount}
                  </span>
                )}
              </Link>
            </li>
          </ul>
        </div>

        {/* Mobile menu */}
        {isMobileMenuOpen && (
          <div className="md:hidden absolute top-0 left-0 w-full h-screen bg-black bg-opacity-90 z-40 flex flex-col items-center justify-center">
            <ul className="flex flex-col items-center space-y-6 text-2xl  text-white">
              <li className="border-b border-gray-700 w-full text-center pb-2">
                <Link
                  to="/"
                  className="hover:text-[#32fa32] transition duration-300"
                  onClick={closeMobileMenu}
                >
                  HOME
                </Link>
              </li>
              <li className="border-b border-gray-700 w-full text-center pb-2">
                <Link
                  to="/about"
                  className="hover:text-[#32fa32] transition duration-300"
                  onClick={closeMobileMenu}
                >
                  ABOUT
                </Link>
              </li>
              <li className="border-b border-gray-700 w-full text-center pb-2">
                <Link
                  to="/customer/dashboard"
                  className="hover:text-[#32fa32] transition duration-300"
                  onClick={closeMobileMenu}
                >
                  DASHBOARD
                </Link>
              </li>
              <li className="border-b border-gray-700 w-full text-center pb-2">
                <Link
                  to="/customer/products"
                  className="hover:text-[#32fa32] transition duration-300"
                  onClick={closeMobileMenu}
                >
                  PLANS
                </Link>
              </li>
              <li className="border-b border-gray-700 w-full text-center pb-2">
                <Link
                  to="/customer/guide"
                  className="hover:text-[#32fa32] transition duration-300"
                  onClick={closeMobileMenu}
                >
                  GUIDE
                </Link>
              </li>
              <li className="border-b border-gray-700 w-full text-center pb-2">
                <Link
                  to="/how-to-use"
                  className="hover:text-[#32fa32] transition duration-300"
                  onClick={closeMobileMenu}
                >
                  HOW TO USE?
                </Link>
              </li>
              <li className="border-b border-gray-700 w-full text-center pb-2">
                <Link
                  to="/contact"
                  className="hover:text-[#32fa32] transition duration-300"
                  onClick={closeMobileMenu}
                >
                  CONTACT
                </Link>
              </li>
              <li className="relative border-b border-gray-700 w-full text-center pb-2">
                <Link
                  to="/customer/cart"
                  className="hover:text-[#32fa32] transition duration-300"
                  onClick={closeMobileMenu}
                >
                  CART
                  {cartItemCount > 0 && (
                    <span className="bg-green-500 text-black text-sm font-semibold rounded-full w-4 h-4 flex items-center justify-center absolute top-0 right-4 transform translate-y-[-50%]">
                      {cartItemCount}
                    </span>
                  )}
                </Link>
              </li>
              {user ? (
                <li className="w-full text-center">
                  <button
                    onClick={() => {
                      handleLogout();
                      closeMobileMenu();
                    }}
                    className="bg-transparent border border-white text-white font-semibold text-xl py-2 px-4 rounded-md transition-colors duration-300 hover:bg-white hover:text-black"
                  >
                    SignOut
                  </button>
                </li>
              ) : (
                <li className="w-full text-center">
                  <Link
                    to="/login"
                    className="bg-transparent text-[#6df76d] font-semibold py-2 px-4 text-xl rounded-md shadow-md transition transform hover:translate-y-1 hover:shadow-lg inline-flex items-center justify-center hover:text-white hover:bg-[#3ce959]"
                    onClick={closeMobileMenu}
                  >
                    SignIn
                  </Link>
                </li>
              )}
            </ul>
          </div>
        )}
      </nav>
    </div>
  );
};

export default Header;
