import React from "react";
import { Helmet } from "react-helmet";

const Disclaimer = () => {
  return (
    <div className="disclaimer">
      <Helmet>
        <title>Disclaimer - Toolzstream</title>
        <meta
          name="description"
          content="Read Toolzstream's Disclaimer to understand our liability and limitations regarding the information provided on our website. Learn more about our terms and conditions."
        />
        <meta property="og:title" content="Disclaimer - Toolzstream" />
        <meta
          property="og:description"
          content="Read Toolzstream's Disclaimer to understand our liability and limitations regarding the information provided on our website. Learn more about our terms and conditions."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://toolzstream.me/disclaimer" />
        <meta
          property="og:image"
          content="https://toolzstream.me/static/media/1.97e910600390c01e45a7.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Disclaimer - Toolzstream" />
        <meta
          name="twitter:description"
          content="Read Toolzstream's Disclaimer to understand our liability and limitations regarding the information provided on our website. Learn more about our terms and conditions."
        />
        <meta
          name="twitter:image"
          content="https://toolzstream.me/static/media/1.97e910600390c01e45a7.png"
        />
      </Helmet>
      <div className="max-w-7xl mx-auto font-mono px-4 sm:px-6 lg:px-8 py-16">
        <br />
        <div className="text-center">
          <h1 className="text-4xl sm:text-5xl font-extrabold text-green-400 mb-4">
            Disclaimer
          </h1>
          <p className="text-lg text-gray-600 mb-8">
            If you require any more information or have any questions about our
            site’s disclaimer, please feel free to contact us by email at{" "}
            <a
              href="mailto:support@toolzstream.me"
              className="text-blue-600 hover:underline"
            >
              support@Toolzstream.me
            </a>
            .
          </p>
        </div>
        <div className="text-gray-600 mb-4">
          <p className="mb-4">
            All the information on this website is published in good faith and
            for general information purpose only. Toolzstream does not make any
            warranties about the completeness, reliability, and accuracy of this
            information. Any action you take upon the information you find on
            this website (Toolzstream), is strictly at your own risk.
            Toolzstream will not be liable for any losses and/or damages in
            connection with the use of our website.
          </p>
          <p className="mb-4">
            Please be also aware that when you leave our website, other sites
            may have different privacy policies and terms which are beyond our
            control. Please be sure to check the Privacy Policies of these sites
            as well as their “Terms of Service” before engaging in any business
            or uploading any information.
          </p>
          <h2 className="text-3xl font-bold text-green-300 mb-4">Consent</h2>
          <p className="mb-4">
            By using our website, you hereby consent to our disclaimer and agree
            to its terms.
          </p>
          <h2 className="text-3xl font-bold text-green-300 mb-4">Update</h2>
          <p className="mb-4">
            This site disclaimer was last updated on March 20, 2019. Should we
            update, amend or make any changes to this document, those changes
            will be prominently posted here.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
