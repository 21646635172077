import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import UserContextProvider from "./ContextApi/userContextApi";
import Routesroute from "./Routes/Routesroute";
import Header from "./Pages/Header";
import Footer from "./Pages/Footer";
import WelcomeNotification from "./pushNotification/WelcomeNotification"; // Ensure this import path is correct
import "./index.css";
import { CartProvider } from "./ContextApi/CartContextProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

const warnOnConsoleOpen = () => {
  const messageStyle = `
    font-size: 20px;
    font-weight: bold;
    color: red;
    background: yellow;
    padding: 10px;
    border: 1px solid red;
  `;

  const warningMessage1 =
    "⚠️ Don't do this! Your account could get hacked by a hacker.";
  const warningMessage2 =
    "⚠️ Don't do this! Your account could get suspended by doing this.";
  const warningMessage3 =
    "⚠️ Don't do this! if u know what u doing Come work with us.";

  console.log(`%c${warningMessage1}`, messageStyle);
  console.log(`%c${warningMessage2}`, messageStyle);
  console.log(`%c${warningMessage3}`, messageStyle);
  console.log(`%c${warningMessage1}`, messageStyle);
  console.log(`%c${warningMessage2}`, messageStyle);
  console.log(`%c${warningMessage3}`, messageStyle);
  console.log(`%c${warningMessage1}`, messageStyle);
  console.log(`%c${warningMessage2}`, messageStyle);
  console.log(`%c${warningMessage3}`, messageStyle);
  console.log(`%c${warningMessage1}`, messageStyle);
  console.log(`%c${warningMessage2}`, messageStyle);
  console.log(`%c${warningMessage3}`, messageStyle);
};

const handleKeyDown = (event) => {
  if (
    event.key === "F12" ||
    (event.ctrlKey && event.shiftKey && event.key === "I")
  ) {
    setTimeout(warnOnConsoleOpen, 100);
  }
};

document.addEventListener("keydown", handleKeyDown);

root.render(
  <BrowserRouter>
    <UserContextProvider>
      <CartProvider>
        <Header />
        <WelcomeNotification />
        <br />
        <br />
        <br />
        <Routesroute />
        <Footer />
      </CartProvider>
    </UserContextProvider>
  </BrowserRouter>
);
