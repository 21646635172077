import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUser } from "../ContextApi/userContextApi";
import { format, parseISO } from "date-fns"; // Import date-fns functions

const Notification = () => {
  const { backend } = useUser();
  const [notifications, setNotifications] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [expandedNotifications, setExpandedNotifications] = useState({});
  const notificationsPerPage = 10;

  // Calculate total pages
  const totalPages = Math.ceil(notifications.length / notificationsPerPage);

  // Get current notifications
  const indexOfLastNotification = currentPage * notificationsPerPage;
  const indexOfFirstNotification =
    indexOfLastNotification - notificationsPerPage;
  const currentNotifications = notifications.slice(
    indexOfFirstNotification,
    indexOfLastNotification
  );

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const toggleNotification = (id) => {
    setExpandedNotifications((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${backend}/api/notifications`);
        const sortedNotifications = response.data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setNotifications(sortedNotifications);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };
    fetchNotifications();
  }, [backend]);

  return (
    <div className="container mx-auto p-4 min-h-screen items-center justify-center">
      <br />
      <br />
      <br />
      <br />
      <h1 className="text-3xl font-bold mb-4 text-center text-white font-mono">
        Notifications
      </h1>
      <div className="space-y-4 cursor-pointer">
        {currentNotifications.map((notification) => (
          <div
            key={notification.id}
            className="flex flex-col px-3 py-1 bg-white gap-4 rounded-lg border border-gray-100 my-3 overflow-hidden"
            onClick={() => toggleNotification(notification.id)}
          >
            <div className="flex items-center">
              <div className="relative w-16 h-16 flex-shrink-0 rounded-full bg-gradient-to-r from-purple-400 via-blue-500 to-red-400">
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-14 h-14 bg-gray-200 rounded-full border-2 border-white">
                  <img
                    className="w-full h-full object-cover rounded-full"
                    src={
                      notification.imageUrl || "https://via.placeholder.com/150"
                    }
                    alt={notification.sender}
                  />
                </div>
              </div>
              <div className="flex-grow ml-4 overflow-hidden">
                <span
                  className={`font-mono block ${
                    expandedNotifications[notification.id] ? "" : "truncate"
                  }`}
                >
                  {notification.message}
                </span>
                <div className="text-[#207520] font-mono text-sm">{`From: ${notification.sender}`}</div>
                <div className="text-gray-500 text-sm">
                  {`Date: ${format(
                    parseISO(notification.date),
                    "MMMM d, yyyy @ h:mm a"
                  )}`}
                </div>
              </div>
              <div className="flex-shrink-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                </svg>
              </div>
            </div>
            {expandedNotifications[notification.id] && (
              <div className="mt-2 text-gray-700">
                <p>{notification.message}</p>
              </div>
            )}
          </div>
        ))}
      </div>
      {notifications.length > 10 && (
        <div className="flex justify-between mt-4">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Previous
          </button>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default Notification;
