import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useUser } from "../ContextApi/userContextApi";
import videoThumbnail from "../Assest/svg/otts.gif";
import Logo from "../Assest/svg/Live.png";

const ReferAndEarn = () => {
  const { user } = useUser();
  const referralLink = user?.refID || "ToolzStream";
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(referralLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  };

  return (
    <div className="py-30">
      <Helmet>
        <title>Toolzstream - Refer and Earn</title>
        <meta
          name="description"
          content="Refer friends and family to Toolzstream and earn 10% off your next purchase. Share your unique referral link and enjoy the benefits!"
        />
        <meta
          name="keywords"
          content="Toolzstream, refer and earn, referral program, discounts, premium services"
        />
        <meta property="og:title" content="Toolzstream - Refer and Earn" />
        <meta
          property="og:description"
          content="Refer friends and family to Toolzstream and earn 10% off your next purchase. Share your unique referral link and enjoy the benefits!"
        />
        <meta
          property="og:url"
          content="https://toolzstream.me/refer-and-earn"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={Logo} />
      </Helmet>
      <div className="md:max-w-[80%] w-[90%] mx-auto flex flex-col-reverse md:flex-row mt-10 items-center">
        <div className="md:w-1/2 w-[90%] md:pr-12 md:text-right text-center">
          <h1 className="md:text-4xl text-center text-[20px] font-bold my-3 font-mono text-green-400 mb-8">
            Refer and Earn
          </h1>
          <p className="md:text-lg text-[13px] text-white font-mono mb-8">
            Share your unique referral link with friends and family. When they
            make a purchase using your link, you'll get 10% off on your next
            purchase.
          </p>
          <div className="bg-[#ffffff0e] md:text-right text-center p-4 rounded-md border border-green-500 mb-4">
            <p className="text-white">Your Referral Code:</p>
            {user ? (
              <>
                <input
                  type="text"
                  value={referralLink}
                  readOnly
                  className="w-full mt-2 p-2 bg-black text-white rounded-md"
                />
                <button
                  onClick={handleCopy}
                  className={`mt-4 font-semibold py-2 px-4 rounded-md transition-colors duration-300 ${
                    copied
                      ? "bg-[#00800000] text-white border border-green-500"
                      : "bg-green-500 text-black"
                  }`}
                >
                  {copied ? "Copied" : "Copy Code"}
                </button>
              </>
            ) : (
              <>
                <div className="w-full mt-2 p-2 bg-black text-white rounded-md">
                  Please log in to get your referral code.
                </div>
              </>
            )}
          </div>
          <h3 className="text-xl text-white text-center font-semibold mb-2">
            Benefits:
          </h3>
          <ul className=" list-inside  list-none text-center md:text-[12px]  text-white space-y-2">
            <li>
              Get 10% off on your next purchase for each successful referral.
            </li>
            <li>No limit to the number of referrals you can make.</li>
          </ul>
        </div>
        <div className="md:w-1/2 flex justify-center">
          <img
            src={videoThumbnail}
            alt="Refer and Earn at Toolzstream"
            className="w-full rounded-3xl bg-black shadow-xl"
          />
        </div>
      </div>
    </div>
  );
};

export default ReferAndEarn;
