import React, { createContext, useState, useContext, useEffect } from "react";
import { sha256 } from "js-sha256"; // You need to install js-sha256 using npm

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

const generateHash = (data) => {
  return sha256(JSON.stringify(data));
};

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    const savedCartHash = localStorage.getItem("cartItemsHash");

    if (savedCartItems && savedCartHash) {
      const parsedCartItems = JSON.parse(savedCartItems);
      const calculatedHash = generateHash(parsedCartItems);

      if (calculatedHash === savedCartHash) {
        return parsedCartItems;
      }
    }

    return [];
  });

  useEffect(() => {
    const cartHash = generateHash(cartItems);
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    localStorage.setItem("cartItemsHash", cartHash);
  }, [cartItems]);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "cartItems" || event.key === "cartItemsHash") {
        const savedCartItems = localStorage.getItem("cartItems");
        const savedCartHash = localStorage.getItem("cartItemsHash");

        if (savedCartItems && savedCartHash) {
          const parsedCartItems = JSON.parse(savedCartItems);
          const calculatedHash = generateHash(parsedCartItems);

          if (calculatedHash === savedCartHash) {
            setCartItems(parsedCartItems);
          } else {
            localStorage.setItem("cartItems", JSON.stringify(cartItems));
            localStorage.setItem("cartItemsHash", generateHash(cartItems));
          }
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [cartItems]);

  const addToCart = (product) => {
    setCartItems((prevItems) => [...prevItems, product]);
  };

  const removeFromCart = (index) => {
    setCartItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  const incrementQuantity = (index) => {
    setCartItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const decrementQuantity = (index) => {
    setCartItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const clearCart = () => {
    setCartItems([]);
  };

  const value = {
    cartItems,
    addToCart,
    removeFromCart,
    incrementQuantity,
    decrementQuantity,
    clearCart,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
