import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Logo from "../Assest/svg/Live.png";

const Frequently = () => {
  const [isOpen, setIsOpen] = useState({
    1: false,
    2: false,
    3: false,
  });

  const toggleAnswer = (id) => {
    setIsOpen((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <div className="p-4 md:p-8 w-[90%] mx-auto">
      <Helmet>
        <title>
          Toolzstream - Affordable Premium OTT Plans and Website Subscriptions
        </title>
        <meta
          name="description"
          content="Toolzstream offers premium OTT plans and website subscriptions at affordable prices. Discover over 50 essential tools with our simple one-click access system."
        />
        <meta
          name="keywords"
          content="Toolzstream, premium OTT plans, affordable subscriptions, website accounts, cheap subscriptions, essential tools"
        />
        <meta
          property="og:title"
          content="Toolzstream - Affordable Premium OTT Plans and Website Subscriptions"
        />
        <meta
          property="og:description"
          content="Get premium OTT plans and website accounts at Toolzstream. Enjoy affordable subscription services at a fraction of the original cost."
        />
        <meta property="og:url" content="https://toolzstream.me" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={Logo} />
      </Helmet>
      <div className="md:flex flex-row items-center justify-between">
        <div className="md:w-1/3 flex flex-col justify-center items-center mb-8 md:mb-0">
          <h1 className="md:text-4xl text-[19px] font-mono text-gray-200 font-bold mb-4 text-center md:text-left">
            Frequently Asked Questions
          </h1>
          <p className="text-gray-600 text-center md:text-left md:text-lg text-[13px] font-mono">
            Have questions about ToolzStream? Explore common queries about our
            service and support. Need more information? Contact us via live chat
            or email.
          </p>
        </div>
        <div className="md:w-1/2">
          <div className="space-y-4 md:text-lg text-[13px] font-mono">
            {[
              {
                id: 1,
                question: "Do You Offer Refunds?",
                answer:
                  "No, we do not offer refunds. Please check our refund policy for more details.",
              },
              {
                id: 2,
                question: "Is There a Free Trial?",
                answer:
                  "Yes, we offer a free trial for new users. Explore our services risk-free!",
              },
              {
                id: 3,
                question: "How Can I Cancel My Subscription?",
                answer:
                  "You can cancel your subscription anytime from your account settings.",
              },
            ].map((faq) => (
              <div
                key={faq.id}
                className="md:p-4 p-2 border rounded-lg shadow-sm cursor-pointer bg-white hover:bg-gray-100 transition duration-300"
                onClick={() => toggleAnswer(faq.id)}
              >
                <div className="flex justify-between items-center">
                  <span className="font-medium md:text-lg text-[12px]">
                    {faq.question}
                  </span>
                  {isOpen[faq.id] ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  )}
                </div>
                {isOpen[faq.id] && (
                  <p className="mt-2 md:text-lg text-[12px] text-gray-600">
                    {faq.answer}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Frequently;
