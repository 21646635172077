import React, { useEffect, useState } from "react";
import { useUser } from "../ContextApi/userContextApi";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useCart } from "../ContextApi/CartContextProvider";
import { Helmet } from "react-helmet";
import { ToastContainer, toast as showToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Dashboard = () => {
  const { user, logoutUser, backend } = useUser();
  const { clearCart } = useCart();
  const [avatarUrl, setAvatarUrl] = useState("");
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [visibleOrdersCount, setVisibleOrdersCount] = useState(10); // Number of orders to show initially
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (user && user.username) {
      const canvas = document.createElement("canvas");
      canvas.width = 100;
      canvas.height = 100;
      const context = canvas.getContext("2d");

      context.fillStyle = "#ff8562";
      context.fillRect(0, 0, canvas.width, canvas.height);

      context.fillStyle = "#fff";
      context.font = "50px Arial";
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillText(
        user.username[0].toUpperCase(),
        canvas.width / 2,
        canvas.height / 2
      );

      setAvatarUrl(canvas.toDataURL());
    }
  }, [user]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(`${backend}/api/orders/${user._id}`);
        if (response.ok) {
          const data = await response.json();
          setOrders(data.orders.reverse()); // Reverse to get the latest orders first
        } else {
          console.error("Failed to fetch orders");
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    if (user) {
      fetchOrders();
    }
  }, [user, backend]);

  const fetchOrderById = async (orderId) => {
    try {
      const response = await fetch(`${backend}/api/orders/get/${orderId}`);
      if (response.ok) {
        const data = await response.json();
        setSelectedOrder(data.order);
      } else {
        console.error("Failed to fetch order by ID");
      }
    } catch (error) {
      console.error("Error fetching order by ID:", error);
    }
  };

  const handleLogout = () => {
    logoutUser();
    clearCart();
    showToast.success("Logout Successful", {
      position: "top-right",
    });
    navigate("/login");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const checkExpiredProducts = async () => {
      if (user && user.whenExpire && user.whenExpire.length > 0) {
        const currentDate = new Date();
        user.whenExpire.forEach(async (expireItem) => {
          const expireDate = new Date(expireItem.expireDate);
          if (expireDate <= currentDate) {
            try {
              const response = await fetch(`${backend}/api/auth/expired`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  email: user.email,
                  productId: expireItem.productId,
                }),
              });

              if (response.ok) {
                console.log("Expired product handled successfully");
              } else {
                console.error("Failed to handle expired product");
              }
            } catch (error) {
              console.error("Error handling expired product:", error);
            }
          }
        });
      }

      // Set timeout to run the check again after a certain period
      setTimeout(checkExpiredProducts, 10000); // 10 seconds
    };

    // Run the check immediately on component mount
    checkExpiredProducts();

    // Clean up function to clear timeout if the component unmounts
    return () => clearTimeout();
  }, [user, backend]);

  const handleCopy = () => {
    navigator.clipboard.writeText(user.refID).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  const handleShowMoreOrders = () => {
    setVisibleOrdersCount((prevCount) => prevCount + 10);
  };

  const displayedOrders = orders.slice(0, visibleOrdersCount);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 w-[98%] md:w-[90%] mx-auto">
      <Helmet>
        <title>ToolzStream - Dashboard</title>
        <meta
          name="description"
          content="View your dashboard on ToolzStream. Manage account details, orders, and active plans. Logout securely from ToolzStream."
        />
        <meta
          name="keywords"
          content="ToolzStream, user dashboard, account details, orders, active plans, logout"
        />
        <meta property="og:title" content="ToolzStream - Dashboard" />
        <meta
          property="og:description"
          content="View your dashboard on ToolzStream. Manage account details, orders, and active plans. Logout securely from ToolzStream."
        />
        <meta
          property="og:url"
          content="https://toolzstream.me/customer/dashboard"
        />
        <meta property="og:type" content="website" />
      </Helmet>

      {user ? (
        <div className="w-full max-w-4xl p-4 sm:p-8 bg-white bg-opacity-80 rounded-lg shadow-lg mt-20 sm:mt-40 border border-green-500">
          <div className="flex flex-col sm:flex-row items-center justify-between mb-5">
            <div className="flex items-center mb-4 sm:mb-0">
              <div className="relative w-16 h-16 sm:w-20 sm:h-20 mr-4">
                <div className="rounded-full overflow-hidden bg-gray-200 border-4 border-green-500 shadow-md">
                  <img
                    src={avatarUrl}
                    alt="Avatar"
                    className="object-cover w-full h-full"
                  />
                </div>
              </div>
              <div>
                <p className="text-2xl sm:text-3xl font-bold text-gray-800">
                  {user.username}
                </p>
                <p className="text-gray-600">{user.email}</p>
              </div>
            </div>
            <button
              className="px-6 py-3 border border-white text-[#15440e] font-semibold rounded-full transition-colors duration-300 hover:bg-[white] hover:text-black shadow-lg hover:shadow-xl"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
            <div>
              <strong className="text-lg text-gray-700">
                User Information:
              </strong>
              <p className="text-base sm:text-lg text-gray-700 mt-2">
                <strong>Username:</strong> {user.username}
              </p>
              <p className="text-base sm:text-lg text-gray-700">
                <strong>Email:</strong> {user.email}
              </p>
              <p className="text-base sm:text-lg text-gray-700">
                <strong>Role:</strong> {user.isAdmin ? "Admin" : "User"}
              </p>
              <p className="text-base sm:text-lg text-gray-700">
                <strong>Account created on:</strong>{" "}
                {format(new Date(user.date), "PP")}
              </p>

              <p className="text-base sm:text-lg text-gray-700">
                Learn more about secure account management on{" "}
                <a
                  href="https://www.security.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green-500 hover:underline"
                >
                  Security.org
                </a>
                .
              </p>
            </div>
            <div>
              <strong className="text-lg text-gray-700">Active Plans:</strong>
              {user.cookies.length > 0 ? (
                <ul className="list-disc list-inside text-base sm:text-lg text-gray-700 mt-2">
                  {user.cookies.map((plan, index) => (
                    <li key={index} className="text-gray-700">
                      {plan}
                    </li>
                  ))}
                </ul>
              ) : (
                <span className="text-base sm:text-lg text-gray-700">
                  No active plans
                </span>
              )}
            </div>
          </div>

          <div className="mt-8 flex flex-col justify-center items-center space-y-3">
            {" "}
            <span>
              <strong>Referral Code :</strong> {user.refID}
            </span>{" "}
            <button
              onClick={handleCopy}
              className="px-6 py-3 border border-green-500 text-green-500 font-semibold rounded-full transition-colors duration-300 hover:bg-green-500 hover:text-white shadow-lg hover:shadow-xl"
            >
              {isCopied ? "Copied!" : "Copy Code"}
            </button>
          </div>

          <div className="w-full">
            <strong className="text-lg text-gray-700">Order History:</strong>
            {displayedOrders.length > 0 ? (
              <ul className="list-disc list-inside text-base sm:text-lg text-gray-700 mt-2">
                {displayedOrders.map((order) => (
                  <li
                    key={order._id}
                    className="text-gray-700 cursor-pointer hover:text-green-600"
                    onClick={() => fetchOrderById(order._id)}
                  >
                    {order.orderNumber} -{" "}
                    {format(new Date(order.createdAt), "PP")}
                  </li>
                ))}
              </ul>
            ) : (
              <span className="text-base sm:text-lg text-gray-700">
                No orders found
              </span>
            )}

            {orders.length > visibleOrdersCount && (
              <button
                onClick={handleShowMoreOrders}
                className="mt-4 px-6 py-3 border border-green-500 text-green-500 font-semibold rounded-full transition-colors duration-300 hover:bg-green-500 hover:text-white shadow-lg hover:shadow-xl"
              >
                See More
              </button>
            )}
          </div>

          {selectedOrder && (
            <div className="mt-8 p-4 bg-gray-100 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold mb-2">Order Details</h3>
              <p className="mb-2">
                <strong>Order Number:</strong> {selectedOrder._id}
              </p>
              <p className="mb-2">
                <strong>Date:</strong>{" "}
                {format(new Date(selectedOrder.createdAt), "PP")}
              </p>
              <p className="mb-2">
                <strong>Status:</strong> {selectedOrder.status}
              </p>
              <p className="mb-2">
                <strong>Razorpay :</strong> {selectedOrder.razorpayOrder}
              </p>
              {/* Add more details as needed */}
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center min-h-screen">
          <p className="text-2xl text-white">
            Please log in to view your dashboard.
          </p>
          <button
            className="mt-4 px-6 py-3 border border-[green] text-[#ffffff] font-semibold rounded-full transition-colors duration-300 hover:bg-[white] hover:text-black shadow-lg hover:shadow-xl"
            onClick={() => navigate("/login")}
          >
            Login
          </button>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default Dashboard;
