import React, { useState } from "react";
import axios from "axios";

const AdminAdd = () => {
  const [imageName, setImageName] = useState("");
  const [imageLink, setImageLink] = useState("");
  const [cookies, setCookies] = useState("");
  const [link, setLink] = useState("");

  const handleAddProduct = async () => {
    try {
      const response = await axios.post("http://localhost:5000/api/products", {
        imageName,
        imageLink,
        cookies,
        link,
      });

      if (response.status === 201) {
        alert("Product added successfully");
        window.location.reload();
      } else {
        console.error("Failed to add product");
      }
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  return (
    <div className=" flex justify-center text-white items-center h-screen">
      <div className="flex flex-col  items-center space-y-4">
        <h1 className="text-2xl font-bold">Add Product</h1>

        <input
          type="text"
          placeholder="Image Name"
          value={imageName}
          onChange={(e) => setImageName(e.target.value)}
          className="w-full px-4 py-2 text-black bg-gray-200 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-gray-500"
        />
        <input
          type="text"
          placeholder="Image Link"
          value={imageLink}
          onChange={(e) => setImageLink(e.target.value)}
          className="w-full px-4 py-2 text-black bg-gray-200 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-gray-500"
        />
        <input
          type="text"
          placeholder="*********"
          value={cookies}
          onChange={(e) => setCookies(e.target.value)}
          className="w-full px-4 py-2 text-black bg-gray-200 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-gray-500"
        />
        <input
          type="text"
          placeholder="link"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          className="w-full px-4 py-2 text-black bg-gray-200 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-gray-500"
        />
        <button
          onClick={handleAddProduct}
          className="w-full px-4 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Add Product
        </button>
      </div>
    </div>
  );
};

export default AdminAdd;
