import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../components/Home";
import Products from "../Products/Products";
import ProductDetails from "../Products/productId";

import Notfound from "../Pages/Notfound";
import Test from "../Pages/test";
import Login from "../Auth/Login";
import Register from "../Auth/Register";
import DashBoard from "../components/DashBoard";
import Cart from "../components/Cart";
import Guide from "../Pages/Guide";
import Forget from "../Auth/Forget";

const Customer = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/products" element={<Products />} />
      <Route path="/products/:id" element={<ProductDetails />} />{" "}
      {/* Add the new route */}
      <Route path="/dashboard" element={<DashBoard />} />
      <Route path="*" element={<Notfound />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forget" element={<Forget />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/guide" element={<Guide />} />
      <Route path="/test" element={<Test />} />
    </Routes>
  );
};

export default Customer;
