import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminAdd from "../Admin/AdminAdd";

const Admin = () => {
  return (
    <Routes>
      <Route path="adminadd" element={<AdminAdd />} />
    </Routes>
  );
};

export default Admin;
