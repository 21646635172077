import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUser } from "../ContextApi/userContextApi";
import { useCart } from "../ContextApi/CartContextProvider";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast as showToast } from "react-toastify";
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Pages/Loader";

const Products = () => {
  const { user, logoutUser, backend } = useUser();
  const { addToCart, clearCart } = useCart();
  const navigate = useNavigate();

  const [userProducts, setUserProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  const [isExtensionInstalled, setIsExtensionInstalled] = useState(null);

  useEffect(() => {
    // Function to check if the extension is installed
    const checkExtension = () => {
      window.postMessage(
        {
          type: "CHECK_EXTENSION",
        },
        "*"
      );
    };

    // Handle response from the extension
    const handleMessage = (event) => {
      const { type, installed } = event.data;

      if (type === "CHECK_EXTENSION_RESPONSE") {
        setIsExtensionInstalled(installed);
      }
    };

    window.addEventListener("message", handleMessage);

    checkExtension();

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${backend}/api/products`);
        setProductData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product data:", error);
        setLoading(false);
      }
    };

    fetchProductData();
  }, [backend]);

  const fetchUserProducts = async () => {
    if (user?.cookies?.length) {
      setLoading(true);
      try {
        const productPromises = user.cookies.map((cookieId) =>
          axios.get(`${backend}/api/products/${cookieId}`)
        );
        const productResponses = await Promise.all(productPromises);
        const products = productResponses.map((response) => response.data);

        // Find the Entertainment Pack product if it exists
        const entertainmentPack = products.find(
          (product) => product.imageName === "Entertainment Pack"
        );

        // If Entertainment Pack exists, fetch and combine its contents
        if (entertainmentPack) {
          const comboProductIds = entertainmentPack.cookies.map(
            (cookie) => cookie.Name
          );
          const comboProductPromises = comboProductIds.map((id) =>
            axios.get(`${backend}/api/products/${id}`)
          );
          const comboProductResponses = await Promise.all(comboProductPromises);
          const comboProducts = comboProductResponses.map(
            (response) => response.data
          );

          // Combine all products except the Entertainment Pack itself
          const filteredUserProducts = products
            .filter((product) => product.imageName !== "Entertainment Pack")
            .concat(comboProducts);

          setUserProducts(filteredUserProducts);
        } else {
          setUserProducts(products);
        }
      } catch (error) {
        console.error("Error fetching user products:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUseNowClick = async (product) => {
    try {
      const response = await axios.post(`${backend}/api/use-now`, {
        productId: product._id,
      });

      const { cookies, targetUrl } = response.data;
      console.log("target", targetUrl);

      window.postMessage(
        {
          type: "SET_COOKIES_AND_REDIRECT",
          payload: { cookies, targetUrl },
        },
        "*"
      );
    } catch (error) {
      console.error("Error using product:", error);
    }
  };

  const handleLogout = () => {
    logoutUser();
    clearCart();
    navigate("/login");
  };

  const handleButtonClick = (productId) => {
    navigate(`/customer/products/${productId}`);
  };

  useEffect(() => {
    if (!user) {
      setIsLoggedIn(false);
      return;
    }
    window.scrollTo(0, 0);
    fetchUserProducts();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      window.location.reload();
    }, 200000); // 600000 ms = 10 minutes

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const filteredProductData = productData.filter(
    (product) =>
      !userProducts.some((userProduct) => userProduct._id === product._id)
  );

  return (
    <div className="flex flex-col justify-center items-center md:max-w-[90%] w-[95%] mx-auto h-auto min-h-screen mb-10 text-white">
      <Helmet>
        <title>ToolzStream - Active Plans and Products</title>
        <meta
          name="description"
          content="Explore active subscription plans and products on ToolzStream. Buy plans, add to cart, and use instantly with ToolzStream."
        />
        <meta
          name="keywords"
          content="ToolzStream, active plans, products, subscription plans, add to cart, use now, entertainment pack"
        />
        <meta
          property="og:title"
          content="ToolzStream - Active Plans and Products"
        />
        <meta
          property="og:description"
          content="Explore active subscription plans and products on ToolzStream. Buy plans, add to cart, and use instantly with ToolzStream."
        />
        <meta
          property="og:url"
          content="https://toolzstream.me/customer/products"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <br />
      <br />

      <div className="my-10 w-full mt-10">
        <h1 className="md:text-4xl text-[25px] text-center font-mono mt-10 ">
          Active Plans
        </h1>

        {isLoggedIn ? (
          <div className="my-10 w-full mt-10">
            {loading ? (
              <Loader />
            ) : userProducts.length > 0 ? (
              <div className=" flex flex-col justify-center items-center ">
                <div className="flex flex-wrap justify-center  items-center ">
                  {userProducts.map((product) => (
                    <div
                      key={product._id}
                      className="border-white border rounded-xl shadow-md overflow-hidden transform hover:-translate-y-1 hover:scale-105 transition-all duration-300 md:w-[200px] w-[150px] m-4 bg-black"
                    >
                      <img
                        src={product.imageLink}
                        alt="Product"
                        className="h-24 w-full object-cover rounded-t-xl overflow-hidden"
                      />
                      <div className="p-4">
                        <h4 className="text-sm font-bold text-gray-100 text-center">
                          {product.imageName || product.Name}
                        </h4>
                        <div className="flex justify-center items-center space-x-2">
                          {isExtensionInstalled === null ? (
                            <Link
                              to="/customer/guide"
                              className="px-4 py-2 bg-green-500 text-white rounded shadow-md hover:bg-green-600"
                            >
                              Install Extension
                            </Link>
                          ) : isExtensionInstalled ? (
                            <button
                              className="mt-2 w-full bg-white text-green-500 py-1 px-2 rounded-full shadow-md font-semibold uppercase hover:bg-green-600 hover:text-white transition-colors duration-300"
                              onClick={() => handleUseNowClick(product)}
                            >
                              Use Now
                            </button>
                          ) : (
                            <Link
                              to="/guide"
                              className="px-4 py-2 bg-green-500 text-white rounded shadow-md hover:bg-green-600"
                            >
                              Install Extension
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div>
                  If product is not working then follow{" "}
                  <a href="/help" className=" text-blue-500">
                    Link.
                  </a>
                </div>
              </div>
            ) : (
              <p className="text-xl font-semibold text-center mt-8">
                You don't have any active plans.{" "}
                <a href="/customer/products" className="text-blue-500">
                  Buy Now
                </a>
                <span className="text-gray-700 flex flex-col">
                  <span>
                    If your plans aren't visible, please{" "}
                    <span
                      onClick={handleLogout}
                      className="text-blue-500 underline cursor-pointer"
                    >
                      Login
                    </span>{" "}
                    again.
                  </span>
                </span>
              </p>
            )}
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center h-auto mt-10">
            <h2 className=" font-semibold md:text-[25px] text-[16px]">
              Please log in to view your active plans.
            </h2>
            <a
              href="/login"
              className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
            >
              Log In
            </a>
          </div>
        )}
      </div>

      <div
        id="target-element"
        className="border-t-2 border-gray-500 w-full mt-8 pt-8"
      >
        <h1 className="md:text-4xl text-center text-[18px] font-bold font-mono mt-28">
          Locked Plans
        </h1>
        <div className="flex-wrap flex justify-center items-center">
          {filteredProductData.map((product) => (
            <div
              key={product._id}
              className="border-white border rounded-xl shadow-md overflow-hidden transform hover:-translate-y-1 hover:scale-105 transition-all duration-300 md:w-[200px] w-[150px] m-4 bg-black"
            >
              <img
                src={product.imageLink}
                alt="Product"
                className="h-24 w-full object-cover rounded-t-xl overflow-hidden"
              />

              <div className="p-4">
                <h4 className="text-sm font-bold text-gray-100 text-center">
                  {product.imageName || product.Name}{" "}
                  <span className="ml-10">₹{product.price}</span>
                </h4>
                <div className="flex text-green-400 flex-wrap justify-center items-center space-x-2">
                  {Array.isArray(product.cookies) && product.cookies.length > 0
                    ? product.cookies.map((cookie, index) => (
                        <h4
                          key={index}
                          className="md:text-sm text-[12px] font-thin"
                        >
                          {cookie.id}
                        </h4>
                      ))
                    : null}
                </div>
                <div className="flex justify-center items-center space-x-2">
                  <button
                    className="w-full bg-white text-green-700 py-1 px-2 rounded-full shadow-md font-semibold uppercase hover:bg-green-600 hover:text-white transition-colors duration-300 md:text-[18px] text-[15px]"
                    onClick={() => handleButtonClick(product._id)}
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <ToastContainer autoClose={3000} />
    </div>
  );
};

export default Products;
