import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useUser } from "../ContextApi/userContextApi";
import { useCart } from "../ContextApi/CartContextProvider";
import { toast as showToast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProductDetails = () => {
  const { id } = useParams();
  const { backend } = useUser();
  const { addToCart } = useCart();
  const [added, setAdded] = useState(false);
  const [activeSection, setActiveSection] = useState("desktop");
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(`${backend}/api/products/${id}`);
        setProduct(response.data);
        setLoading(false);

        // Check if the product is already in the cart
        const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        const isInCart = cartItems.some(
          (item) => item._id === response.data._id
        );
        setAdded(isInCart);
      } catch (error) {
        console.error("Error fetching product details:", error);
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [id, backend]);

  if (loading) {
    return <div className="text-center text-white">Loading...</div>;
  }

  if (!product) {
    return <div className="text-center text-red-500">Product not found</div>;
  }

  const handleAddToCart = (product) => {
    addToCart(product);
    setAdded(true);

    showToast.success(`${product.imageName} added to cart`, {
      position: "top-right",
    });
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const sectionVideos = {
    desktop: "EtsauOIXHWI",
    mobile: "FxbYrJc10Es",
  };

  return (
    <div className="min-h-screen text-white flex font-mono flex-col items-center py-10 px-4">
      <br />
      <br />
      <br />
      <h1 className="text-3xl mt-5 font-bold mb-6 text-center border-b-4 border-green-500 pb-2">
        {product.imageName || product.Name}
      </h1>
      <div className="flex flex-col md:flex-row justify-center items-start w-full md:w-4/5 lg:w-3/5 mb-6">
        <div className="flex justify-center items-center border-2 border-green-500 rounded-lg shadow-md mb-6 md:mb-0 md:mr-6 bg-gray-900 p-4 w-full md:w-1/2 h-90">
          <img
            src={product.imageLink}
            alt={product.imageName || product.Name}
            className="object-center w-full h-full rounded-lg shadow-lg"
          />
        </div>
        <div className="bg-black md:p-6 p-3 rounded-lg shadow-md w-full md:w-1/2 border-2 border-green-500 flex flex-col justify-between">
          <div className="text-center mb-4">
            <p className="text-2xl text-green-500 font-semibold">
              Price: ₹{product.price}/Month
            </p>
            {product.originalPrice && (
              <p className="text-lg text-gray-500 line-through">
                Original Price: ₹{product.originalPrice}
              </p>
            )}
            {Array.isArray(product.cookies) && product.cookies.length > 0 && (
              <p className="md:text-lg text-sm text-gray-500">
                Combo OF:
                {product.cookies.map((cookie, index) => (
                  <span
                    key={index}
                    className={`cookie-item-${index} text-${getColor(
                      index
                    )}-500`}
                  >
                    {" "}
                    {cookie.id},
                  </span>
                ))}
              </p>
            )}
            <p className="text-base text-yellow-500 mt-2">
              The product will expire after 28th day of purchase.
            </p>
          </div>
          <div>
            <button
              className={`w-full bg-green-500 text-white font-semibold py-3 px-4 rounded-lg transition duration-300 mb-4 ${
                added
                  ? "cursor-not-allowed bg-[#00800000] border"
                  : "cursor-pointer"
              }`}
              onClick={() => handleAddToCart(product)}
              disabled={added}
            >
              {added ? <>Added In Cart</> : <>Add to Cart</>}
            </button>
            <div className="bg-[#4242420e] drop-shadow-lg mb-1 shadow-lg p-4 rounded-lg md:text-sm text-[10px] text-orange-500 text-center">
              Warning: If your account is shared with more users, it will be
              temporarily held or could be suspended.
            </div>
            {product.imageName === "Netflix" ? (
              <div className="bg-[#4242420e] drop-shadow-lg shadow-lg p-4 rounded-lg md:text-sm text-[10px] text-red-500 text-center">
                Warning: Netflix only works on PC.
              </div>
            ) : (
              <div className="bg-[#4242420e] md:hidden display drop-shadow-lg shadow-lg p-4 rounded-lg md:text-sm text-[10px] text-orange-500 text-center">
                Notice: Use desktop mode if you are a mobile user to enjoy
                services.
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="bg-[#80808017] md:p-6 p-3 rounded-lg shadow-md w-full md:w-4/5 lg:w-3/5 mt-4 text-center border-2 border-white">
        <h2 className="text-lg font-semibold mb-2 border-b-2 border-green-500 pb-2">
          Product Details
        </h2>
        <p className="text-mono md:text-sm text-[13px]">
          {product.description}
        </p>
      </div>

      <div className="bg-[#8080801a] p-6 rounded-lg shadow-md w-full md:w-4/5 lg:w-3/5 mt-4 text-center border-2 border-white">
        <h2 className="text-lg font-semibold mb-2 border-b-2 border-green-500 pb-2">
          How To use
        </h2>
        <div className="guide-container text-white flex flex-col items-center justify-center">
          <div className="max-w-4xl w-full md:p-8 p-1 rounded-lg shadow-lg">
            <div className="flex justify-center">
              <button
                className={`mr-4 text-sm sm:text-base py-3 px-6 rounded-full ${
                  activeSection === "desktop"
                    ? "bg-green-500 hover:bg-green-600 text-white font-semibold"
                    : "bg-gray-500 hover:bg-gray-600 text-white font-medium"
                }`}
                onClick={() => handleSectionChange("desktop")}
              >
                Desktop
              </button>
              <button
                className={`text-sm sm:text-base py-3 px-6 rounded-full ${
                  activeSection === "mobile"
                    ? "bg-green-500 hover:bg-green-600 text-white font-semibold"
                    : "bg-gray-500 hover:bg-gray-600 text-white font-medium"
                }`}
                onClick={() => handleSectionChange("mobile")}
              >
                Mobile
              </button>
            </div>

            <div className="mb-8">
              <h3 className="text-2xl sm:text-3xl font-bold mb-4">
                Video Tutorial
              </h3>
              <div className="aspect-w-16 aspect-h-100 rounded-lg overflow-hidden backdrop-filter backdrop-blur-md bg-opacity-40 bg-gray-700">
                <iframe
                  className="w-full md:h-[50vh] h-full"
                  src={`https://www.youtube.com/embed/${sectionVideos[activeSection]}`}
                  title="Extension Tutorial"
                  allowFullScreen
                ></iframe>
              </div>
            </div>

            <h2 className="md:text-sm text-[10px] font-semibold mb-2 border-b-2 border-green-500 pb-2">
              Either Watch the Videos Or Follow{" "}
              <a href="/customer/guide" className=" text-blue-500 underline">
                Steps
              </a>
              .
            </h2>
            <h2 className="md:text-sm text-[10px] font-semibold mb-2 border-b-2 border-green-500 pb-2">
              For help and assistance follow{" "}
              <a href="/contact" className="text-blue-500 underline">
                Click Here
              </a>
              .
            </h2>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

const getColor = (index) => {
  const colors = [
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "indigo",
    "violet",
  ];
  return colors[index % colors.length];
};

export default ProductDetails;
