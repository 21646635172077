import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { AiFillDiscord } from "react-icons/ai";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, message } = formData;
    const mailtoLink = `mailto:support@toolzstream.me?subject=Contact from ${name}&body=Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;
    window.location.href = mailtoLink;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact-us">
      <Helmet>
        <title>Contact Us - Toolzstream</title>
        <meta
          name="description"
          content="Get in touch with Toolzstream's support team for any queries or issues. Find answers to frequently asked questions and learn how to contact us for further assistance."
        />
        <meta property="og:title" content="Contact Us - Toolzstream" />
        <meta
          property="og:description"
          content="Get in touch with Toolzstream's support team for any queries or issues. Find answers to frequently asked questions and learn how to contact us for further assistance."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://toolzstream.me/contact-us" />
        <meta
          property="og:image"
          content="https://toolzstream.me/static/media/1.97e910600390c01e45a7.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us - Toolzstream" />
        <meta
          name="twitter:description"
          content="Get in touch with Toolzstream's support team for any queries or issues. Find answers to frequently asked questions and learn how to contact us for further assistance."
        />
        <meta
          name="twitter:image"
          content="https://toolzstream.me/static/media/1.97e910600390c01e45a7.png"
        />
      </Helmet>
      <div className="max-w-7xl mx-auto font-mono px-4 sm:px-6 lg:px-8 py-16">
        <br />
        <div className="text-center">
          <h1 className="text-4xl sm:text-5xl font-extrabold text-green-400 mb-4">
            Contact Us
          </h1>
          <p className="text-lg text-gray-600 mb-8">
            Have any questions or need assistance? Reach out to us!
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-12">
          <div className="contact-us-questions text-gray-600 mb-4">
            <h2 className="text-3xl font-bold text-green-300 mb-4">
              Frequently Asked Questions
            </h2>
            <ul className="list-disc ml-5 space-y-2">
              <li>Request for Tools Update</li>
              <li>Any Offer/Discount?</li>
              <li>How to access tool?</li>
              <li>"XYZ" Tool not working or asking login?</li>
              <li>Payment failed/Pending?</li>
              <li>How to save Canva Design for the lifetime?</li>
              <li>Adding project allowed in tool?</li>
              <li>Can I use tools on mobile devices?</li>
              <li>Can I use/buy your website/tools worldwide?</li>
              <li>Why are your prices lower than the official prices?</li>
              <li>Can I customize the order and make a small combo?</li>
            </ul>
            <p className="mt-4">
              For any other help, message our support team at{" "}
              <a
                href="mailto:support@toolzstream.me"
                className="text-blue-600 hover:underline"
              >
                support@toolzstream.me
              </a>
              .
            </p>
          </div>
          <div className="contact-us-form">
            <h2 className="text-3xl font-bold text-green-300 mb-4">
              Send Us a Message
            </h2>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-300"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-300"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-300"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  required
                ></textarea>
              </div>
              <div>
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-400 hover:bg-green-500"
                >
                  Send Message
                </button>
              </div>
            </form>
            <div className="mt-10 text-center">
              <a
                href="https://discord.gg/RkQRZXsV"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-[#e24fb1] text-5xl transition duration-300"
              >
                <AiFillDiscord />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
