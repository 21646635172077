import React from "react";

const Tns = () => {
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <div className=" w-[88%] mx-auto font-mono rounded-xl drop-shadow shadow-xl  my-5 text-gray-900 p-6 md:p-12">
        <h1 className="text-3xl font-bold mb-6 text-center text-white">
          Terms & Conditions
        </h1>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-white">Billing</h2>
          <p className="text-gray-500">
            ToolzStream does not charge recurring fees. All transactions are
            initiated by the user, and we do not save any billing-related data.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-white">Services</h2>
          <p className="text-gray-500">
            ToolzStream reserves the right to change your account limits,
            pricing, availability, validity, and refund policy in any service at
            any time if there is any change on the official site.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-white">
            Refund Process
          </h2>
          <p className="text-gray-500">
            We will provide a refund only when our service does not work for
            more than 3 days (you will get a refund for that service only).
            There will be no refund if your account gets banned or you do not
            use our service. Also, there will be no refund if our tool limits
            get exhausted; you have to wait until the limit refreshes again.
          </p>
          <p className="text-gray-500">
            The above refund policy is not applicable for Ahrefs and unverified
            persons (No refund available for Ahrefs).
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-white">
            Account Ban
          </h2>
          <p className="text-gray-500">
            If a user shares their account details with other people, then
            ToolzStream has the right to permanently ban the user account
            without any notification. Multiple logins from the same IP (using a
            proxy or the same ISP) on the same day can result in account
            suspension.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-white">
            Notification
          </h2>
          <p className="text-gray-500">
            ToolzStream has the right to send you notifications via Email / SMS
            to your registered number/email regarding new offers or for other
            informational purposes.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-white">Important</h2>
          <p className="text-gray-500">
            ToolzStream never allows accessing our website source code, reverse
            engineering on our extension/website. ToolzStream never allows using
            cookies manager extension; if you want to use one, then remove our
            extension and log out from ToolzStream.
          </p>
          <p className="text-gray-500">
            There is no refund if you contact us after 30 days from the order
            placement date, regardless of whether you received the product, your
            account is not active, or you are not able to use it.
          </p>
          <p className="text-gray-500">
            ToolzStream requires AnyDesk or TeamViewer for Technical Support.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-white">
            Testing/Beta Tools
          </h2>
          <p className="text-gray-500">
            ToolzStream has the right to change any beta tools and remove and
            replace them, etc., and no support is available for beta tools
            because we are already testing them. If you want to drop your issue
            feedback, then use your dashboard feedback option. If you want to
            purchase our services just for beta tools, it is entirely at your
            own risk because we are also testing these tools, so no uptime
            guarantee and no refund is available for beta tools.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-white">
            Limitations
          </h2>
          <p className="text-gray-500">
            We do not allow adding projects on any tools, so you can't create
            audit reports. We do not allow API options on any tools. We do not
            allow bulk searches on any tools.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Tns;
