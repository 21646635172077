import React, { useEffect, useState } from "react";

const Test = () => {
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(null);

  useEffect(() => {
    // Function to check if the extension is installed
    const checkExtension = () => {
      console.log("Posting message to check extension");
      window.postMessage(
        {
          type: "CHECK_EXTENSION",
        },
        "*"
      );
    };

    // Handle response from the extension
    const handleMessage = (event) => {
      console.log("Received message:", event.data);
      if (event.origin !== "http://localhost:3000") {
        return;
      }

      const { type, installed } = event.data;

      if (type === "CHECK_EXTENSION_RESPONSE") {
        console.log("Extension check response:", installed);
        setIsExtensionInstalled(installed);
      }
    };

    window.addEventListener("message", handleMessage);

    checkExtension();

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <div className="h-screen flex items-center justify-center bg-black text-white">
      {isExtensionInstalled === null ? (
        <p>Loading...</p>
      ) : isExtensionInstalled ? (
        <button className="px-4 py-2 bg-blue-500 text-white rounded shadow-md hover:bg-blue-600">
          Click Me
        </button>
      ) : (
        <button className="px-4 py-2 bg-green-500 text-white rounded shadow-md hover:bg-green-600">
          Install Extension
        </button>
      )}
    </div>
  );
};

export default Test;
