import React, { useEffect, useState } from "react";
import axios from "axios";
import { useCart } from "../ContextApi/CartContextProvider";
import { useNavigate } from "react-router-dom";
import { useUser } from "../ContextApi/userContextApi";
import { ToastContainer, toast as showToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import img from "../Assest/svg/1.gif";

const PlanSection = () => {
  const { backend, user } = useUser();

  const [productData, setProductData] = useState([]);
  const [addedProducts, setAddedProducts] = useState(new Set());
  const { addToCart, clearCart } = useCart();
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductData = async () => {
      if (!backend) {
        console.error("REACT_APP_BACKEND is not defined");
        return;
      }

      try {
        const response = await axios.get(`${backend}/api/products`);
        setProductData(response.data);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProductData();
  }, [backend]);

  const entertainmentPack = productData.filter(
    (product) => product.imageName === "Entertainment Pack"
  );

  const handleAddToCart = (product) => {
    addToCart(product);
    showToast.success(`${product.imageName} Added to cart`, {
      position: "top-right",
    });
  };

  // Conditional rendering based on user presence
  if (!user) {
    return null;
  }

  return (
    <section className="mb-10">
      <Helmet>
        <title>Toolzstream - Choose Your Plan</title>
        <meta
          name="description"
          content="Choose your ideal plan from Toolzstream. Discover our range of subscription plans tailored for various needs at affordable prices. Unlock premium tools and services today."
        />
        <meta
          name="keywords"
          content="Toolzstream, subscription plans, affordable prices, premium tools, choose your plan"
        />
        <meta property="og:title" content="Toolzstream - Choose Your Plan" />
        <meta
          property="og:description"
          content="Choose your ideal plan from Toolzstream. Discover our range of subscription plans tailored for various needs at affordable prices. Unlock premium tools and services today."
        />
        <meta
          property="og:url"
          content="https://toolzstream.me/customer/products"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={img} />
      </Helmet>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-20">
        <div className="mb-12">
          <h2 className="md:text-4xl text-[24px] font-mono text-center font-bold text-gray-300 mb-4">
            Combo Pack Plan
          </h2>
        </div>

        {showAlert && (
          <div
            onClick={() => {
              navigate("/customer/cart");
            }}
            className="fixed top-4 right-4 bg-green-500 text-white py-2 px-4 rounded-xl shadow-lg cursor-pointer animate-bounce"
          >
            {alertMessage}
          </div>
        )}

        <div className="flex flex-wrap justify-around items-center space-y-2">
          {entertainmentPack.map((product) => (
            <div
              key={product._id}
              className={`flex flex-col mx-auto max-w-lg text-white border-green-500 border rounded-xl p-4 transition-all duration-300 ${
                addedProducts.has(product._id)
                  ? "bg-black hover:bg-gray-800"
                  : "bg-black hover:bg-green-900 "
              }`}
            >
              <div className="uppercase bg-green-600 rounded-t-xl p-2 text-center text-white text-base">
                {product.imageName}
              </div>
              <div className="flex items-center mb-4">
                <span className="font-manrope mr-2 text-2xl font-semibold text-green-400">
                  &#8377;{product.price}
                </span>
                <span className="text-lg text-gray-400">/ month</span>
              </div>
              <ul className="mb-4 space-y-2 text-left text-base text-gray-400">
                {Array.isArray(product.cookies) &&
                product.cookies.length > 0 ? (
                  product.cookies.map((cookie, index) => (
                    <li key={index} className="flex items-center space-x-2">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-green-400"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
                          stroke="currentColor"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>{cookie.id}</span>
                    </li>
                  ))
                ) : (
                  <li className="text-gray-400">No cookies available</li>
                )}
              </ul>
              <button
                className={`py-2 px-4 bg-green-600 shadow-sm rounded-full transition-all duration-300 text-base font-semibold text-white ${
                  addedProducts.has(product._id)
                    ? "opacity-70 cursor-not-allowed"
                    : "hover:bg-green-700"
                }`}
                onClick={() => handleAddToCart(product)}
                disabled={addedProducts.has(product._id)}
              >
                {addedProducts.has(product._id) ? "Added ✅" : "Add to Cart"}
              </button>
            </div>
          ))}
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default PlanSection;
