import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../ContextApi/userContextApi";
import { ToastContainer, toast as showToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import "./register.css"; // Make sure to include necessary styles

const Loader = () => (
  <div className="loader">
    <FontAwesomeIcon icon={faSpinner} spin size="lg" />
  </div>
);

const Register = () => {
  const navigate = useNavigate();
  const { sendRegisterOTP, registerUser } = useUser();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(""); // State for confirm password
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [loadingOTP, setLoadingOTP] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for confirm password visibility

  const handleSendOTP = async () => {
    setLoadingOTP(true);
    try {
      await sendRegisterOTP(email);
      showToast.success("OTP sent successfully!");
      setOtpSent(true);
    } catch (error) {
      showToast.error("Failed to send OTP. Please try again.");
    } finally {
      setLoadingOTP(false);
    }
  };

  const handleResendOTP = () => {
    // Only allow OTP to be resent if it was previously sent
    if (otpSent) {
      handleSendOTP();
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      showToast.error("Passwords do not match.");
      return;
    }

    setLoadingRegister(true);
    try {
      await registerUser(username, email, password, otp);
      showToast.success("Registration successful!");
      setTimeout(() => {
        navigate("/login");
      }, 2000); // 2 seconds delay before redirecting
    } catch (error) {
      showToast.error("Registration failed. Please check OTP details.");
    } finally {
      setLoadingRegister(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex justify-center items-center h-auto text-center ">
      <div className="form-container bg-white p-8 rounded-lg my-28  shadow-lg">
        <p className="title text-2xl font-semibold mb-2">Create account</p>
        <p className="sub-title text-gray-600 mb-4">
          Let's get started with your trial.
        </p>
        <form className="form space-y-4" onSubmit={handleRegister}>
          <input
            type="text"
            className="input"
            placeholder="Name"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            type="email"
            className="input"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              className="input w-full"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
              aria-label={showPassword ? "Hide password" : "Show password"}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>
          <div className="relative">
            <input
              type={showConfirmPassword ? "text" : "password"}
              className="input w-full"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <button
              type="button"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
              aria-label={
                showConfirmPassword
                  ? "Hide confirm password"
                  : "Show confirm password"
              }
            >
              <FontAwesomeIcon
                icon={showConfirmPassword ? faEyeSlash : faEye}
              />
            </button>
          </div>
          {otpSent ? (
            <>
              <input
                type="text"
                className="input"
                placeholder="OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
              <button
                type="button"
                className="form-btn bg-yellow-500 text-white hover:bg-yellow-600"
                onClick={handleResendOTP}
                disabled={loadingOTP}
              >
                {loadingOTP ? <Loader /> : "Resend OTP"}
              </button>
            </>
          ) : (
            <button
              className="form-btn bg-blue-500 text-white hover:bg-blue-600"
              type="button"
              onClick={handleSendOTP}
            >
              {loadingOTP ? <Loader /> : "Get OTP"}
            </button>
          )}
          <button
            type="submit"
            className="form-btn bg-green-500 text-white hover:bg-green-600"
          >
            {loadingRegister ? <Loader /> : "Create account"}
          </button>
        </form>
        <p className="sign-up-label mt-4">
          Already have an account?
          <span
            className="sign-up-link text-blue-500 cursor-pointer"
            onClick={() => navigate("/login")}
          >
            Log in
          </span>
        </p>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Register;
