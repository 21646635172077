import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import Cookies from "js-cookie";

export const UserContext = createContext();

const backend = process.env.REACT_APP_BACKEND;
const api = `${backend}/api/auth`;

const getUserFromCookies = () => {
  const user = Cookies.get("user");
  if (user) {
    try {
      return JSON.parse(user);
    } catch (e) {
      console.error("Error parsing user data from cookies:", e);
      return null;
    }
  }
  return null;
};

const initialState = {
  isUserLoading: false,
  isUserError: false,
  user: getUserFromCookies(),
  userCookies: [],
  userNeed: null,
  userProducts: [],
  isLoggedOut: false, // Add this flag
};

const handleLogoutExtension = (userProducts) => {
  const targetUrls = userProducts.map((product) => product.link);

  window.postMessage(
    {
      type: "SET_COOKIESA",
      payload: { targetUrls },
    },
    "*"
  );
};

const userReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER_LOADING":
      return { ...state, isUserLoading: true, isUserError: false };
    case "SET_USER_DATA":
      Cookies.set("user", JSON.stringify(action.payload.data));
      Cookies.set("token", action.payload.token);
      return {
        ...state,
        isUserLoading: false,
        user: action.payload.data,
        userNeed: action.payload.data,
        isLoggedOut: false, // Reset the flag
      };
    case "SET_USER_COOKIES":
      return { ...state, userCookies: action.payload.cookies };
    case "SET_USER_PRODUCTS":
      return { ...state, userProducts: action.payload.products };
    case "SET_USER_ERROR":
      return { ...state, isUserLoading: false, isUserError: true };
    case "LOGOUT_USER":
      Cookies.remove("user");
      Cookies.remove("token");

      localStorage.removeItem("user");
      return {
        ...state,
        user: null,
        userCookies: [],
        userNeed: null,
        userProducts: [],
        isLoggedOut: true, // Set the flag
      };
    default:
      return state;
  }
};

const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  const setUserNeed = (userData) => {
    dispatch({
      type: "SET_USER_DATA",
      payload: { data: userData },
    });
  };

  const getUser = async (url) => {
    dispatch({ type: "SET_USER_LOADING" });
    try {
      const response = await axios.get(url);
      const user = response.data;
      setUserNeed(user);
    } catch (error) {
      dispatch({ type: "SET_USER_ERROR" });
    }
  };

  const setUser = (userData, token) => {
    dispatch({
      type: "SET_USER_DATA",
      payload: { data: userData, token },
    });
    Cookies.set("user", JSON.stringify(userData));
    Cookies.set("token", token);
  };

  const setUserCookies = (cookies) => {
    dispatch({
      type: "SET_USER_COOKIES",
      payload: { cookies },
    });
  };

  const setUserProducts = (products) => {
    dispatch({
      type: "SET_USER_PRODUCTS",
      payload: { products },
    });
  };

  const loginUser = async (email, password) => {
    dispatch({ type: "SET_USER_LOADING" });
    try {
      const response = await axios.post(`${api}/login`, { email, password });
      const { user, token } = response.data;
      setUser(user, token);
    } catch (error) {
      throw new Error("Invalid email or password");
      dispatch({ type: "SET_USER_ERROR" });
    }
  };

  const sendRegisterOTP = async (email) => {
    dispatch({ type: "SET_USER_LOADING" });
    try {
      await axios.post(`${api}/send-register-otp`, { email });
    } catch (error) {
      throw error;
    }
  };

  const registerUser = async (username, email, password, otp) => {
    dispatch({ type: "SET_USER_LOADING" });
    try {
      const response = await axios.post(`${api}/register`, {
        username,
        email,
        password,
        otp,
      });
      const { user } = response.data;
      dispatch({ type: "SET_USER_DATA", payload: { data: user } });
      return user;
    } catch (error) {
      throw error;
    }
  };

  const getToken = () => {
    return Cookies.get("token");
  };

  const verifyTokenWithSession = async () => {
    const storedToken = getToken();
    const storedUser = getUserFromCookies();
    if (!storedToken || !storedUser) {
      await logoutUser();
      return;
    }
    try {
      const response = await axios.post(
        `${api}/sessions`,
        { email: storedUser.email, token: storedToken },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      const { user, token: validatedToken } = response.data;
      dispatch({
        type: "SET_USER_DATA",
        payload: { data: user, token: validatedToken },
      });
      Cookies.set("token", validatedToken);
    } catch (error) {
      await logoutUser();
      window.location.reload();
    }
  };

  const logoutUser = async () => {
    try {
      const userProducts = await fetchUserProducts();
      handleLogoutExtension(userProducts);

      dispatch({ type: "LOGOUT_USER" });
    } catch (error) {}
  };

  const fetchUserProducts = async () => {
    if (state.user?.cookies?.length) {
      dispatch({ type: "SET_USER_LOADING" });
      try {
        const productPromises = state.user.cookies.map((cookieId) =>
          axios.get(`${backend}/api/products/${cookieId}`)
        );
        const productResponses = await Promise.all(productPromises);
        const products = productResponses.map((response) => response.data);

        const entertainmentPack = products.find(
          (product) => product.imageName === "Entertainment Pack"
        );

        let filteredUserProducts = [];
        if (entertainmentPack) {
          const comboProductIds = entertainmentPack.cookies.map(
            (cookie) => cookie.Name
          );
          const comboProductPromises = comboProductIds.map((id) =>
            axios.get(`${backend}/api/products/${id}`)
          );
          const comboProductResponses = await Promise.all(comboProductPromises);
          const comboProducts = comboProductResponses.map(
            (response) => response.data
          );

          filteredUserProducts = products
            .filter((product) => product.imageName !== "Entertainment Pack")
            .concat(comboProducts);
        } else {
          filteredUserProducts = products;
        }

        dispatch({
          type: "SET_USER_PRODUCTS",
          payload: { products: filteredUserProducts },
        });

        return filteredUserProducts;
      } catch (error) {
        console.error("Error fetching user products:", error);
        return [];
      } finally {
        dispatch({ type: "SET_USER_LOADING" });
      }
    }
  };

  useEffect(() => {
    if (state.isLoggedOut) {
      return;
    }

    const interval = setInterval(() => {
      verifyTokenWithSession();
    }, 10000);

    return () => clearInterval(interval);
  }, [state.isLoggedOut]);

  useEffect(() => {
    const updatedUserCookies = state.user?.cookies || [];
    setUserCookies(updatedUserCookies);
  }, [state.user]);

  return (
    <UserContext.Provider
      value={{
        ...state,
        getUser,
        setUser,
        sendRegisterOTP,
        setUserCookies,
        loginUser,
        logoutUser,
        registerUser,
        verifyTokenWithSession,
        getToken,
        fetchUserProducts,
        backend,
        api,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const userContextValue = useContext(UserContext);
  if (!userContextValue) {
    throw new Error("useUser must be used within a UserContextProvider");
  }
  return userContextValue;
};

export default UserContextProvider;
