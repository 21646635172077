import React from "react";
import { Helmet } from "react-helmet";
import privacyPolicyImg from "../Assest/svg/1.gif";

const PrivacyPolicy = () => {
  return (
    <div className=" text-white p-6 md:p-12">
      <Helmet>
        <title>Privacy Policy - Toolzstream</title>
        <meta
          name="description"
          content="Read Toolzstream's Privacy Policy to understand how we collect, use, and protect your personal information. Learn about our terms of service, refund policy, and more."
        />
        <meta property="og:title" content="Privacy Policy - Toolzstream" />
        <meta
          property="og:description"
          content="Read Toolzstream's Privacy Policy to understand how we collect, use, and protect your personal information. Learn about our terms of service, refund policy, and more."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://toolzstream.me/privacy-policy"
        />
        <meta property="og:image" content={privacyPolicyImg} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Privacy Policy - Toolzstream" />
        <meta
          name="twitter:description"
          content="Read Toolzstream's Privacy Policy to understand how we collect, use, and protect your personal information. Learn about our terms of service, refund policy, and more."
        />
        <meta name="twitter:image" content={privacyPolicyImg} />
      </Helmet>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 font-mono">
        <div className="text-center">
          <h1 className="text-4xl sm:text-5xl font-extrabold text-green-100 mb-4">
            Privacy Policy
          </h1>
          <p className="text-lg text-gray-600 mb-8">
            Welcome to the Privacy Policy of Toolzstream. This page informs you
            of our policies regarding the collection, use, and disclosure of
            personal data when you use our Service and the choices you have
            associated with that data. Please read this Privacy Policy carefully
            before using our services.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-12">
          <div className="privacy-policy-img">
            <img
              src={privacyPolicyImg}
              alt="Privacy Policy"
              className="w-full rounded-lg shadow-lg"
            />
          </div>
          <div className="privacy-policy-content">
            <h2 className="text-3xl font-bold text-green-100 mb-4">
              What Information We Collect
            </h2>
            <p className="text-gray-600 mb-4">
              We collect information that you provide directly to us when you
              use our services, including personal information such as your
              name, email address, payment details, and usage data.
            </p>
            <h2 className="text-3xl font-bold text-green-100 mb-4">
              How We Use Your Information
            </h2>
            <p className="text-gray-600 mb-4">
              The information we collect is used to operate and improve our
              services, communicate with you, process your payments, and provide
              customer support. We may also use your information to comply with
              legal obligations or protect our rights.
            </p>
            <h2 className="text-3xl font-bold text-green-100 mb-4">
              No Refund Policy
            </h2>
            <p className="text-gray-600 mb-4">
              Please note that Toolzstream does not offer refunds for
              subscription payments or purchases made through our platform.
            </p>
            <h2 className="text-3xl font-bold text-green-100 mb-4">
              No Free Trial
            </h2>
            <p className="text-gray-600 mb-4">
              Toolzstream does not provide free trial periods for its services.
            </p>
            <h2 className="text-3xl font-bold text-green-100 mb-4">
              Contact Us
            </h2>
            <p className="text-gray-600 mb-4">
              If you have any questions about this Privacy Policy or our
              practices, please contact us at{" "}
              <a
                href="mailto:support@toolzstream.me"
                className="text-blue-600 hover:underline"
              >
                support@toolzstream.me
              </a>
              .
            </p>
          </div>
        </div>

        <div className="mt-16">
          <h2 className="text-3xl font-bold text-green-100 mb-4">
            Additional Information
          </h2>
          <p className="text-gray-600 mb-4">
            If you require any more information or have any questions about our
            privacy policy, please feel free to contact us.
          </p>
          <p className="text-gray-600 mb-4">
            At seotooladda.com we consider the privacy of our visitors to be
            extremely important. This privacy policy document describes in
            detail the types of personal information is collected and recorded
            by seotooladda.com and how we use it.
          </p>
          <h2 className="text-3xl font-bold text-green-100 mb-4">Log Files</h2>
          <p className="text-gray-600 mb-4">
            Like many other Web sites, seotooladda.com makes use of log files.
            These files merely logs visitors to the site – usually a standard
            procedure for hosting companies and a part of hosting services’s
            analytics. The information inside the log files includes internet
            protocol (IP) addresses, browser type, Internet Service Provider
            (ISP), date/time stamp, referring/exit pages, and possibly the
            number of clicks. This information is used to analyze trends,
            administer the site, track user’s movement around the site, and
            gather demographic information. IP addresses, and other such
            information are not linked to any information that is personally
            identifiable.
          </p>
          <h2 className="text-3xl font-bold text-green-100 mb-4">
            Cookies and Web Beacons
          </h2>
          <p className="text-gray-600 mb-4">
            seotooladda.com uses cookies to store information about visitors’
            preferences, to record user-specific information on which pages the
            site visitor accesses or visits, and to personalize or customize our
            web page content based upon visitors’ browser type or other
            information that the visitor sends via their browser.
          </p>
          <h2 className="text-3xl font-bold text-green-100 mb-4">
            Third Party Privacy Policies
          </h2>
          <p className="text-gray-600 mb-4">
            You should consult the respective privacy policies of these
            third-party ad servers for more detailed information on their
            practices as well as for instructions about how to opt-out of
            certain practices. seotooladda.com’s privacy policy does not apply
            to, and we cannot control the activities of, such other advertisers
            or web sites.
          </p>
          <p className="text-gray-600 mb-4">
            If you wish to disable cookies, you may do so through your
            individual browser options. More detailed information about cookie
            management with specific web browsers can be found at the browsers’
            respective websites. What Are Cookies?
          </p>
          <h2 className="text-3xl font-bold text-green-100 mb-4">
            Children’s Information
          </h2>
          <p className="text-gray-600 mb-4">
            We believe it is important to provide added protection for children
            online. We encourage parents and guardians to spend time online with
            their children to observe, participate in and/or monitor and guide
            their online activity. seotooladda.com does not knowingly collect
            any personally identifiable information from children under the age
            of 13. If a parent or guardian believes that seotooladda.com has in
            its database the personally-identifiable information of a child
            under the age of 13, please contact us immediately (using the
            contact in the first paragraph) and we will use our best efforts to
            promptly remove such information from our records.
          </p>
          <h2 className="text-3xl font-bold text-green-100 mb-4">
            Online Privacy Policy Only
          </h2>
          <p className="text-gray-600 mb-4">
            This privacy policy applies only to our online activities and is
            valid for visitors to our website and regarding information shared
            and/or collected there. This policy does not apply to any
            information collected offline or via channels other than this
            website.
          </p>
          <h2 className="text-3xl font-bold text-green-100 mb-4">Consent</h2>
          <p className="text-gray-600 mb-4">
            By using our website, you hereby consent to our privacy policy and
            agree to its terms.
          </p>
          <h2 className="text-3xl font-bold text-green-100 mb-4">Update</h2>
          <p className="text-gray-600 mb-4">
            This Privacy Policy was last updated on: Tuesday, October 20th,
            2019. Should we update, amend or make any changes to our privacy
            policy, those changes will be posted here.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
