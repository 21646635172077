import React, { useEffect } from "react";
import Header from "../Pages/Header";
import Hero from "../Pages/Hero";
import Seo from "../Pages/Seo";
import Affordable from "../Pages/Affordable";
import PlanSection from "../Pages/PlanSection";
import CustomerReviews from "../Pages/CustomerReviews";
import Frequently from "../Pages/Frequently";
import Footer from "../Pages/Footer";
import ReferAndEarn from "../Pages/ReferAndEarn";
import Logo from "../Assest/svg/Live.png";
import Plans from "../Pages/plans";

const Home = () => {
  useEffect(() => {
    const elements = document.querySelectorAll(".animate-on-load");
    elements.forEach((element, index) => {
      setTimeout(() => {
        element.classList.add("slide-in");
      }, index * 100);
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <head>
        <title>
          Toolzstream - Affordable Premium OTT and Website Subscriptions
        </title>
        <meta
          name="description"
          content="Get premium OTT plans and website accounts at Toolzstream. Enjoy affordable subscription services at a fraction of the original cost."
        />
        <meta
          name="keywords"
          content="Toolzstream, premium OTT plans, affordable subscriptions, website accounts, cheap subscriptions"
        />

        <meta property="og:url" content="https://toolzstream.me" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={Logo} />
      </head>
      <div className=" md:mt-10 ">
        <div className="animate-on-load  ">
          <Hero />
        </div>
        <div className="animate-on-load">
          <Plans />
        </div>
        <div className="animate-on-load">
          <Seo />
        </div>
        <div className="animate-on-load">
          <Affordable />
        </div>
        <div className="animate-on-load">
          <ReferAndEarn />
        </div>
        <div className="animate-on-load">
          <PlanSection />
        </div>
        <div className="animate-on-load">
          <CustomerReviews />
        </div>
        <div className="animate-on-load">
          <Frequently />
        </div>
      </div>
    </div>
  );
};

export default Home;
