import React, { useEffect } from "react";

const WelcomeNotification = () => {
  useEffect(() => {
    // Function to display welcome notification
    const showWelcomeNotification = () => {
      // Check if the browser supports notifications
      if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
        return;
      }

      // Notification options
      const notificationOptions = {
        body: "🎉 Aapke liye kuch khaas hai! Toolzstream pe aaye hain aap, toh abhi premium content ka maza lijiye aur apni streaming ko banayein zabardast! 😍📺",
        icon: "https://toolzstream.me/static/media/1.97e910600390c01e45a7.png", // Use your logo URL
        vibrate: [200, 100, 200], // Vibration pattern (optional)
        tag: "welcome", // Unique identifier for the notification
        renotify: true, // Whether to re-notify if a notification with the same tag is already displayed
      };

      // Check if permission is already granted
      if (Notification.permission === "granted") {
        new Notification("🌟 Special Welcome for You! 🌟", notificationOptions);
      } else if (Notification.permission !== "denied") {
        // Request permission from the user
        Notification.requestPermission().then((permission) => {
          console.log("Notification permission requested, status:", permission);
          if (permission === "granted") {
            new Notification(
              "🌟 Special Welcome for You! 🌟",
              notificationOptions
            );
          }
        });
      } else {
        // console.log("Notification permission denied");
      }
    };

    // Trigger the welcome notification
    showWelcomeNotification();
  }, []);

  return <></>; // This component doesn't render anything visible
};

export default WelcomeNotification;
