import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import seoImage from "../Assest/svg/1.gif";
import Logo from "../Assest/svg/Live.png";
import Mainimg from "../Assest/svg/Gifimg.gif";

const Plans = () => {
  return (
    <div className="seo mt-8 py-10">
      <Helmet>
        <title>Toolzstream - Discover the Perfect Plan</title>
        <meta
          name="description"
          content="Explore tailored packages for digital marketers, content creators, freelancers, video editors, and learners at Toolzstream. Find the perfect plan that suits your needs."
        />
        <meta
          name="keywords"
          content="Toolzstream, tailored packages, digital marketers, content creators, freelancers, video editors, learners"
        />
        <meta
          property="og:title"
          content="Toolzstream - Discover the Perfect Plan"
        />
        <meta
          property="og:description"
          content="Explore tailored packages for digital marketers, content creators, freelancers, video editors, and learners at Toolzstream. Find the perfect plan that suits your needs."
        />
        <meta
          property="og:url"
          content="https://toolzstream.me/customer/products"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={Logo} />
      </Helmet>

      <div className="md:max-w-[80%] w-[90%] font-mono mx-auto flex flex-col lg:flex-row items-center lg:space-x-10">
        <div className="seo-left lg:w-1/2 flex justify-center lg:justify-start mb-8 lg:mb-0">
          <img
            src={Mainimg}
            alt="SEO Services at Toolzstream"
            className="w-[100%] lg:w-[100%] rounded-xl shadow-xl border-2 "
          />
        </div>
        <div className="seo-right lg:w-1/2 text-center lg:text-left">
          <h1 className="md:text-4xl text-[24px] font-mono font-bold text-red-700 mb-8">
            Discover the Perfect Plan
          </h1>
          <p className="md:text-lg text-[15px] font-mono text-white mb-8">
            Explore our tailored packages for digital marketers, content
            creators, freelancers, video editors, and learners. Our plans are
            designed to provide the best tools and services at an affordable
            price.
          </p>
          <p className="md:text-lg text-[15px] font-mono text-white mb-8">
            We offer a wide range of OTT platforms like{" "}
            <span className="text-[#E50914]">Netflix</span>,{" "}
            <span className="text-[#00A8E1]">Amazon Prime</span>,{" "}
            <span className="text-[#F7B928]">Hotstar</span>, supporting 4K
            plans, and tool sites like{" "}
            <span className="text-[#4997E2]">ChatGPT 4</span>,{" "}
            <span className="text-[#00C4CC]">Canva Pro</span>,{" "}
            <span className="text-[#2196F3]">You.com</span>,{" "}
            <span className="text-[#FFB13B]">Gemini</span>. Learning platforms
            like <span className="text-[#D23F31]">Coursera</span>,{" "}
            <span className="text-[#EC5252]">Udemy</span>, and much more are
            coming in the future as well.
          </p>
          <p className="md:text-lg text-[15px] font-mono text-white mb-8">
            At Toolzstream, we provide these services at a fraction of the
            original cost, with 24/7 support and automated, trusted Razorpay
            payments. Our accounts are fully secure and active round the clock.
          </p>
          <Link
            to="/customer/products#target-element"
            className="inline-block md:px-8 px-4 md:py-4 py-2 bg-gradient-to-r from-[#FF7F7F] to-[#FF0000] hover:from-[#FF0000] hover:to-[#FF7F7F] text-white font-semibold rounded-md shadow-md transition duration-300"
          >
            CHOOSE NOW
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Plans;
