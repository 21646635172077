import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// import rubImage from "../Assest/img/otts_3.mp4";
import rubImage from "../Assest/svg/2.gif";
import Logo from "../Assest/svg/Live.png";

const Affordable = () => {
  return (
    <div className="py-30">
      <Helmet>
        <title>Toolzstream - Affordable Pricing for Premium Services</title>
        <meta
          name="description"
          content="Toolzstream offers the best services at affordable prices. We prioritize reliability and ensure everyone can access premium tools and subscriptions without breaking the bank."
        />
        <meta
          name="keywords"
          content="Toolzstream, affordable pricing, premium services, reliable services, cheap subscriptions"
        />
        <meta
          property="og:title"
          content="Toolzstream - Affordable Pricing for Premium Services"
        />
        <meta
          property="og:description"
          content="Toolzstream offers the best services at affordable prices. We prioritize reliability and ensure everyone can access premium tools and subscriptions without breaking the bank."
        />
        <meta
          property="og:url"
          content="https://toolzstream.me/customer/products"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={Logo} />
      </Helmet>
      <div className="md:max-w-[80%] w-[90%]  mx-auto flex flex-col-reverse md:flex-row-reverse  items-center">
        <div className="aff-left md:w-1/2 w-[90%] md:pr-12 md:text-left text-center">
          <h1 className="md:text-4xl text-[20px] font-bold my-3 font-mono text-green-400 mb-8">
            Affordable Pricing
          </h1>
          <p className="md:text-lg text-[13px]   text-white font-mono mb-8">
            At Toolzstream, we prioritize reliability and offer the best
            services at prices everyone can afford. Access premium tools and
            subscriptions without breaking the bank.
          </p>
          <Link
            to="/customer/products#target-element"
            className="inline-block md:px-8 px-2 md:py-4 py-2 bg-gradient-to-r from-green-400 to-green-500 hover:from-green-500 hover:to-green-600 text-white font-semibold rounded-md shadow-md transition duration-300"
          >
            BUY NOW
          </Link>
        </div>
        <div className="aff-right md:w-1/2 flex justify-center">
          <img
            src={rubImage}
            autoPlay
            muted
            loop
            alt="Affordable premium services at Toolzstream"
            className="w-4/5 rounded-3xl bg-black shadow-xl"
          />
        </div>
      </div>
    </div>
  );
};

export default Affordable;
