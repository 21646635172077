import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import seoImage from "../Assest/svg/1.gif";
import Logo from "../Assest/svg/Live.png";
import Mainimg from "../Assest/svg/Gifimg.gif";

// import seoImage from "../Assest/img/otts_2.mp4";

const Seo = () => {
  return (
    <div className="seo mt-8 py-10">
      <Helmet>
        <title>Toolzstream - Discover the Perfect Plan</title>
        <meta
          name="description"
          content="Explore tailored packages for digital marketers, content creators, freelancers, video editors, and learners at Toolzstream. Find the perfect plan that suits your needs."
        />
        <meta
          name="keywords"
          content="Toolzstream, tailored packages, digital marketers, content creators, freelancers, video editors, learners"
        />
        <meta
          property="og:title"
          content="Toolzstream - Discover the Perfect Plan"
        />
        <meta
          property="og:description"
          content="Explore tailored packages for digital marketers, content creators, freelancers, video editors, and learners at Toolzstream. Find the perfect plan that suits your needs."
        />
        <meta
          property="og:url"
          content="https://toolzstream.me/customer/products"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={Logo} />
      </Helmet>

      <div className="max-w-[90%]  mx-auto flex flex-col md:flex-row-reverse items-center">
        <div className="seo-left md:w-1/2 flex justify-center">
          <img
            src={seoImage}
            autoPlay
            muted
            loop
            alt="SEO Services at Toolzstream"
            className="w-4/5  rounded-xl shadow-xl"
          />
        </div>
        <div className="seo-right md:w-1/2 md:pr-24 md:pl-12 text-center md:text-right">
          <h1 className="md:text-4xl text-[22px] font-mono font-bold text-orange-400 mb-8">
            Discover the Perfect Plan
          </h1>
          <p className="md:text-lg text-[13px] font-mono text-white mb-8">
            Explore our tailored packages for digital marketers, content
            creators, freelancers, video editors, and learners. Our plans are
            designed to provide the best tools and services at an affordable
            price.
          </p>
          <Link
            to="/customer/products#target-element"
            className="inline-block md:px-8 px-2 md:py-4 py-2 bg-gradient-to-r from-[#ffad33] to-[#ff7f00] hover:from-[#ff7f00] hover:to-[#ffad33] text-white font-semibold rounded-md shadow-md transition duration-300"
          >
            CHOOSE NOW
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Seo;
