import React from "react";
import { Helmet } from "react-helmet";
import logo from "../Assest/logo.png";

const Hero = () => {
  return (
    <div className="md:h-screen h-[60vh] flex flex-col md:justify-center justify-end items-center">
      <Helmet>
        <title>
          Toolzstream - Affordable Premium OTT Plans and Website Subscriptions
        </title>
        <meta
          name="description"
          content="Toolzstream offers premium OTT plans and website subscriptions at affordable prices. Discover over 50 essential tools with our simple one-click access system."
        />
        <meta
          name="keywords"
          content="Toolzstream, premium OTT plans, affordable subscriptions, website accounts, cheap subscriptions, essential tools"
        />
        <meta
          property="og:title"
          content="Toolzstream - Affordable Premium OTT Plans and Website Subscriptions"
        />
        <meta
          property="og:description"
          content="Get premium OTT plans and website accounts at Toolzstream. Enjoy affordable subscription services at a fraction of the original cost."
        />
        <meta property="og:url" content="https://toolzstream.me" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={logo} />
      </Helmet>
      <div className="md:w-[75%]  w-[95%] mx-auto flex flex-col justify-center items-center px-4">
        <div className="hero-left md:w-[80%] mx-auto bg-cover bg-no-repeat bg-left text-center py-12 rounded-lg shadow-xl backdrop-filter backdrop-blur-lg bg-opacity-50">
          <h1
            id="textstyle"
            className="md:text-7xl text-4xl font-extrabold md:my-5 my-10 leading-tight text-white"
          >
            Access to Premium OTT IN AFFORDABLE PRICE
          </h1>
          <p className="md:text-lg text-[13px] font-mono text-white mb-8">
            Enjoy premium OTT services at half the price of original
            subscriptions. Our platform offers a seamless access system with
            just one click. Experience top-tier entertainment without breaking
            the bank!
          </p>
          <a
            href="/how-to-use"
            className="md:px-8 px-2 md:py-4 py-2 bg-gradient-to-r from-[#7edb62c9] to-[#23b90e] hover:from-[#23b90e] hover:to-[#81ff5b] hover:text-black hover:scale-105 ease-in-out duration-300 text-white font-semibold rounded-md shadow-md transition"
          >
            How to Use
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
