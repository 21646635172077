import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";

export default function Notfound() {
  const location = useLocation();

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-black">
      <div className="bg-green-600 bg-opacity-90 backdrop-filter backdrop-blur-lg rounded-lg p-8 text-white text-center shadow-lg">
        <h1 className="text-6xl font-semibold text-red-500 mb-4">404</h1>
        <p className="text-lg mb-4">Oops! Looks like you're lost.</p>
        <p className="text-lg mb-4">Plz Try Re-Login</p>
        <div className="animate-bounce">
          <svg
            className="mx-auto h-16 w-16 text-red-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
            />
          </svg>
        </div>
        <p className="mt-4">
          Let's get you back{" "}
          <NavLink
            to="/"
            className="text-blue-500 hover:underline transition duration-300"
          >
            Home
          </NavLink>
          .
        </p>
      </div>
    </div>
  );
}
