import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCart } from "../ContextApi/CartContextProvider.jsx";
import { useUser } from "../ContextApi/userContextApi.jsx";
import { ToastContainer, toast as showToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate, useNavigate } from "react-router-dom";

const Cart = () => {
  const { getUser, api, user, backend, getToken } = useUser();

  const storedToken = getToken();
  const navigate = useNavigate();

  const { cartItems = [], removeFromCart, clearCart } = useCart();

  const [totalPrice, setTotalPrice] = useState(0);
  const [referralCode, setReferralCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [referralStatus, setReferralStatus] = useState(null);

  useEffect(() => {
    const calculateTotalPrice = () => {
      return cartItems.reduce(
        (total, item) => total + parseFloat(item.price),
        0
      );
    };

    setTotalPrice(calculateTotalPrice());
  }, [cartItems]);

  useEffect(() => {
    if (user) {
      const referralCoins = user.refadd ?? 0; // Use nullish coalescing to default to 0
      const referralDiscount = referralCoins * 10; // 1 coin = ₹10
      setDiscount(referralDiscount);
    } else {
      setDiscount(0);
    }
  }, [user]);

  const handleNavigate = () => {
    navigate("/customer/dashboard");
  };

  const userId = user?._id;
  const valueOfOneCoin = 10;
  const maxReferralCoinsToUse = Math.floor(totalPrice / valueOfOneCoin);
  const referralCoinsToUse = Math.min(user?.refadd ?? 0, maxReferralCoinsToUse); // Default to 0 if refadd is null or undefined
  const discounts = referralCoinsToUse * valueOfOneCoin;
  const finalPrice = totalPrice - discounts;

  const handleCheckout = async () => {
    try {
      if (!userId) {
        showToast.error("You must be logged in to proceed with checkout.", {
          position: "top-right",
        });
        return;
      }

      const token = getToken();
      if (!token) {
        showToast.error(
          "Please log in again, authentication token not found.",
          {
            position: "top-right",
          }
        );
        return;
      }

      if (referralCode === user.refID) {
        showToast.error("You cannot use your own referral code.", {
          position: "top-right",
        });
        return;
      }

      const valueOfOneCoin = 10;
      const maxReferralCoinsToUse = Math.floor(totalPrice / valueOfOneCoin);
      const referralCoinsToUse = Math.min(
        user.refadd ?? 0,
        maxReferralCoinsToUse
      ); // Default to 0 if refadd is null or undefined
      const discount = referralCoinsToUse * valueOfOneCoin;
      const finalPrice = totalPrice - discount;

      const orderData = {
        userId,
        totalPrice: finalPrice,
        cartItems: cartItems.map((item) => item._id),
        referralCoinsUsed: referralCoinsToUse,
      };

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const {
        data: { key },
      } = await axios.get(`${backend}/api/getkey`, { headers });

      const { data: order } = await axios.post(
        `${backend}/api/orders/create`,
        orderData,
        { headers }
      );

      const options = {
        key,
        amount: Math.round(finalPrice * 100),
        currency: "INR",
        name: "ToolzStream.me",
        description: "Payment Gateway",
        image: "https://toolzstream.me/static/media/1.97e910600390c01e45a7.png",
        order_id: order.id,
        prefill: {
          name: user?.name || "User",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#FFA500",
        },
        handler: async (response) => {
          try {
            const verificationResponse = await axios.post(
              `${backend}/api/payment/verify`,
              {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
              },
              { headers }
            );

            if (verificationResponse.data.success) {
              showToast.success("Payment Successful.", {
                position: "top-right",
              });

              await axios.put(
                `${backend}/api/update-cookies`,
                {
                  userId,
                  productId: cartItems.map((item) => item._id),
                  refid: referralCode,
                  referralCoinsUsed: referralCoinsToUse,
                },
                { headers }
              );

              await axios.put(
                `${backend}/api/update-referral-coins`,
                {
                  userId,
                  referralCoinsUsed: referralCoinsToUse,
                },
                { headers }
              );

              navigate("/customer/products");
              clearCart();
            } else {
              showToast.error("Payment failed.", {
                position: "top-right",
              });
            }
          } catch (error) {
            showToast.error("Payment verification failed.", {
              position: "top-right",
            });
          }
        },
      };

      const razor = new window.Razorpay(options);

      razor.on("payment.failed", function (response) {
        showToast.error("Payment failed.", {
          position: "top-right",
        });
      });

      razor.open();
    } catch (error) {
      if (error.response) {
        console.error("Server error:", error.response.data);
      }
      showToast.error("Oops! Something went wrong. Error opening checkout.", {
        position: "top-right",
      });
    }
  };

  const handleApplyReferral = async () => {
    try {
      const response = await axios.post(
        `${backend}/api/verify-referral`,
        { referralCode },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      if (response.data.valid) {
        setReferralStatus("applied");
      } else {
        setReferralStatus("invalid");
      }
    } catch (error) {
      console.error("Error verifying referral code:", error);
      setReferralStatus("invalid");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = () => {
    clearCart();
  };

  return (
    <section className="md:py-16 py-8">
      <br />

      <div className="mx-auto max-w-screen-xl min-h-screen px-4 2xl:px-0">
        <div className="flex flex-row my-6  justify-between items-center">
          <h2 className="text-xl  font-semibold text-gray-300 uppercase dark:text-gray sm:text-2xl">
            Shopping Cart
          </h2>
          <button
            className="mt-2 md:text-xl text-[13px] md:py-2 p-1 md:px-4 px-2 bg-transparent border border-white text-white  rounded-full font-semibold uppercase hover:bg-white hover:text-black transition-colors duration-300"
            onClick={handleClick}
          >
            Clear Cart
          </button>
        </div>

        <div className="mt-6 sm:mt-8 md:gap-6 lg:grid lg:grid-cols-2 xl:gap-8 justify-center items-start">
          <div className="mx-auto w-full flex-none lg:max-w-2xl xl:max-w-4xl">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {cartItems.map((product, index) => (
                <div
                  key={product._id}
                  className="bg-white relative p-2 rounded-2xl shadow-xl overflow-hidden transform hover:-translate-y-1 hover:scale-105 transition-all duration-300"
                >
                  <button
                    onClick={() => removeFromCart(index)}
                    className="absolute top-2 right-2 bg-[#ff0000] text-white p-2 rounded-full hover:bg-red-600 focus:outline-none"
                  >
                    <svg
                      className="w-6 h-6"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  <img
                    src={product.imageLink}
                    alt={
                      product.imageName ||
                      product.Name ||
                      "ToolzStream Product Image"
                    }
                    className="h-32 w-full rounded-t-2xl object-cover"
                  />
                  <div className="p-2">
                    <div className="flex  flex-col  items-center justify-between">
                      <div className=" flex flex-row md:flex-col justify-between w-full ">
                        <h4 className="text-sm font-bold text-gray-700 truncate">
                          {product.imageName || product.Name || "Product Image"}
                        </h4>
                        <h4 className="text-sm  text-right font-bold text-gray-700 truncate">
                          - ₹{product.price}
                        </h4>
                      </div>
                      <div className="flex text-green-400 w-full justify-center items-center space-x-2">
                        {Array.isArray(product.cookies) &&
                          product.cookies.length > 0 && (
                            <p className=" text-sm text-gray-500">
                              Combo OF:
                              {product.cookies.map((cookie, index) => (
                                <span
                                  key={index}
                                  className={`cookie-item-${index} text-${getColor(
                                    index
                                  )}-500`}
                                >
                                  {" "}
                                  {cookie.id},
                                </span>
                              ))}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mx-auto mt-6 max-w-4xl lg:mt-0 lg:w-full font-mono">
            <span
              className="bg-[#5e5e5e2d] rounded-t-xl text-white drop-shadow-md p-2 border-t-2 cursor-pointer"
              onClick={handleNavigate}
            >
              Referral Coin: {user?.refadd ?? 0}
            </span>

            <div className="rounded-lg border border-gray-200 bg-[#ffffff0a] bg-opacity-90 bg-blur p-6 shadow-lg dark:border-gray-700">
              <p className="text-2xl font-semibold text-white dark:text-white">
                Order Summary
              </p>

              <div className="space-y-6">
                <div className="space-y-4">
                  <div className="flex justify-between">
                    <div className="text-white">Total Price:</div>
                    <div className="text-white">₹{totalPrice}</div>
                  </div>
                </div>

                {discount > 0 && (
                  <div className="flex justify-between mt-2 text-green-400">
                    <span>Discount Applied:</span>
                    <div className=" flex md:flex-row flex-col text-right space-x-4 ">
                      <span className=" text-[yellow]">
                        ({referralCoinsToUse} Coin Used)
                      </span>
                      <span>- ₹{discounts.toFixed(2)}</span>
                    </div>
                  </div>
                )}
                <div className="flex justify-between mt-4 text-gray-200 font-semibold">
                  <span>Final Price:</span>
                  <span>₹{finalPrice.toFixed(2)}</span>
                </div>

                {totalPrice > 100 ? (
                  <div className="mt-4 mb-6">
                    <div className="mt-4 flex items-center">
                      <input
                        type="text"
                        placeholder="Referral Code"
                        className="flex-1 py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FFA500]"
                        value={referralCode}
                        onChange={(e) => setReferralCode(e.target.value)}
                      />
                      <button
                        onClick={handleApplyReferral}
                        className="ml-2 bg-[#FFA500] text-white py-2 px-4 rounded-md hover:bg-[#e69500] transition-colors duration-300"
                      >
                        Apply
                      </button>
                    </div>

                    {referralStatus && (
                      <p
                        className={`mt-2 text-sm font-medium ${
                          referralStatus === "applied"
                            ? "text-green-600"
                            : "text-red-600"
                        }`}
                      >
                        {referralStatus === "applied" ? "Applied" : "Invalid"}
                      </p>
                    )}
                  </div>
                ) : (
                  <div className="bg-[#8080801c] p-4 rounded-md">
                    <p className="text-sm font-medium text-gray-300">
                      Spend more than ₹100 to use a referral code.
                    </p>
                  </div>
                )}

                <button
                  onClick={handleCheckout}
                  className="w-full rounded-lg bg-[#FF4500] py-3 px-5 text-center text-sm font-semibold text-white transition-colors duration-300 hover:bg-[#FF5722] focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Proceed to Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

const getColor = (index) => {
  const colors = ["red", "blue", "green", "orange", "yellow", "yellow"];
  return colors[index % colors.length];
};

export default Cart;
