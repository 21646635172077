// src/Loader.js
import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="loader-overlay">
      <div className="loader-container">
        <div className="loader-text">
          <h1 className="text-white font-mono text-3xl">Loading...</h1>
          <p className="text-white font-mono">
            Please wait while we connect to the server.
          </p>
        </div>
        <div className="loader-bar"></div>
      </div>
    </div>
  );
};

export default Loader;
