import React, { useState, useEffect } from "react";
import { useUser } from "../ContextApi/userContextApi";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Guide = () => {
  const { user } = useUser();

  // Initialize state
  const [activeSection, setActiveSection] = useState("desktop");
  const [browserType, setBrowserType] = useState(
    localStorage.getItem("browserType") || "other"
  );

  // Handle section change and store it in localStorage
  const handleSectionChange = (section) => {
    setActiveSection(section);
    localStorage.setItem("activeSection", section);
  };

  // Determine browser type and store it in localStorage
  const getBrowserType = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("firefox") > -1) {
      return "firefox";
    }
    return "other";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const detectedBrowserType = getBrowserType();
    setBrowserType(detectedBrowserType);
    localStorage.setItem("browserType", detectedBrowserType);

    // Check screen size to determine activeSection
    const updateActiveSection = () => {
      if (window.innerWidth <= 768) {
        // Mobile breakpoint
        setActiveSection("mobile");
      } else {
        // Desktop
        setActiveSection("desktop");
      }
    };

    updateActiveSection(); // Set the initial value
    window.addEventListener("resize", updateActiveSection);

    return () => window.removeEventListener("resize", updateActiveSection);
  }, []);

  const sectionVideos = {
    desktop: "EtsauOIXHWI",
    mobile: "FxbYrJc10Es",
  };

  const installationSteps = {
    desktop: {
      firefox: [
        <>
          Step 1: Install the ToolzStream extension directly from the Firefox
          Add-ons site:
          <a
            className="text-blue-400 ml-1"
            target="_blank"
            rel="noopener noreferrer"
            href="https://addons.mozilla.org/en-US/firefox/addon/toolzstream-me/"
          >
            ToolzStream Firefox Extension
          </a>
          .
        </>,
        <>
          Step 2: After installation, visit the{" "}
          <Link className="text-blue-400" to="/customer/products">
            Plans
          </Link>{" "}
          page to access your plans.
        </>,
      ],
      other: [
        <>
          Step 1: Download the PC extension file from this link:
          <a
            className="text-blue-400 ml-1"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.mediafire.com/file/hn8ulgs9fszmwqy/ToolzStream.zip/file"
          >
            ToolzStream.zip
          </a>
          .
        </>,
        "Step 2: Open Google Chrome.",
        "Step 3: Go to the menu (three dots) > More tools > Extensions.",
        "Step 4: Enable 'Developer mode' at the bottom.",
        "Step 5: Click 'Load unpacked' and select the extension folder.",
        <>
          Step 6: Go to the{" "}
          <Link className="text-blue-400" to="/customer/products">
            Plans
          </Link>{" "}
          page and enjoy your plans!
        </>,
      ],
    },
    mobile: [
      <>
        Step 1: Install the Firefox browser from your app store if you don't
        already have it.
      </>,
      <>
        Step 2: Open the Firefox browser and go to the{" "}
        <a
          className="text-blue-400"
          target="_blank"
          rel="noopener noreferrer"
          href="https://toolzstream.me"
        >
          ToolzStream.me
        </a>
        .
      </>,
      <>
        Step 3: Install the ToolzStream extension directly from the Firefox
        <a
          className="text-blue-400 ml-1"
          target="_blank"
          rel="noopener noreferrer"
          href="https://addons.mozilla.org/en-US/firefox/addon/toolzstream-me/"
        >
          Extension
        </a>
        .
      </>,
      "Step 4: Refresh the page after installation.",
      <>
        Step 5: Visit the{" "}
        <Link className="text-blue-400" to="/customer/products">
          Plans
        </Link>{" "}
        page to access your plans and enjoy the benefits!
      </>,
    ],
  };

  const isUserLoggedIn = user && user.cookies;

  const currentSteps =
    activeSection === "desktop"
      ? installationSteps.desktop[browserType]
      : installationSteps[activeSection];

  return (
    <div className="guide-container mb-10 text-white p-3 min-h-screen flex flex-col items-center justify-center">
      <br />
      <Helmet>
        <title>ToolzStream - Extension Guide</title>
        <meta
          name="description"
          content="Learn how to download, install, and use the Subscription Management Extension on PC and mobile devices. Follow our step-by-step guide and video tutorial for easy setup."
        />
        <meta
          name="keywords"
          content="ToolzStream, extension guide, download extension, install extension, use extension, PC installation, mobile installation, video tutorial"
        />
        <meta property="og:title" content="ToolzStream - Extension Guide" />
        <meta
          property="og:description"
          content="Learn how to download, install, and use the Subscription Management Extension on PC and mobile devices. Follow our step-by-step guide and video tutorial for easy setup."
        />
        <meta
          property="og:url"
          content="https://toolzstream.me/guide/extension"
        />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="max-w-4xl mt-20 w-full font-mono md:p-8 p-5 rounded-lg shadow-lg bg-[#ffffff09] border border-[#2de62d]">
        <h2 className="text-2xl sm:text-5xl font-bold text-center mb-8">
          Use Guide for {browserType === "firefox" ? "Firefox" : "Browsers"}
        </h2>

        <div className="mb-8">
          <h3 className="text-xl sm:text-3xl font-bold mb-4">Video Tutorial</h3>
          <div className="w-full md:h-[50vh] h-[20vh] rounded-lg overflow-hidden backdrop-filter backdrop-blur-md bg-opacity-40 bg-gray-700">
            <iframe
              className="w-full h-full"
              src={`https://www.youtube.com/embed/${sectionVideos[activeSection]}`}
              title="Extension Tutorial"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        {(activeSection === "desktop" || activeSection === "mobile") && (
          <div className="mb-8">
            <h3 className="text-xl text-center sm:text-3xl font-bold mb-4">
              Installation for{" "}
              {activeSection === "desktop" ? "PC" : "Mobile Devices"}
            </h3>
            <div className="flex flex-col md:flex-row gap-4">
              <div className="flex-1 rounded-lg overflow-hidden backdrop-filter backdrop-blur-md bg-opacity-40 bg-black p-6">
                <h4 className="text-xl sm:text-2xl font-semibold mb-4">
                  {activeSection === "desktop"
                    ? "PC (Desktop/Laptop)"
                    : "Mobile Devices"}
                </h4>
                <ul className="space-y-2 list-decimal list-inside">
                  {currentSteps.map((step, index) => (
                    <li key={index} className="text-sm sm:text-base">
                      {step}
                    </li>
                  ))}
                </ul>
                {isUserLoggedIn ? (
                  <a
                    href="https://addons.mozilla.org/en-US/firefox/addon/toolzstream-me/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block mt-4 text-center bg-blue-500 hover:bg-blue-600 text-white py-3 px-6 rounded-full font-semibold transition duration-300 text-sm sm:text-base"
                  >
                    Download Extension for{" "}
                    {activeSection === "desktop" ? "PC" : "Mobile"}
                  </a>
                ) : (
                  <a
                    href="/customer/products"
                    className="block mt-4 text-center bg-blue-500 hover:bg-blue-600 text-white py-3 px-6 rounded-full font-semibold transition duration-300 text-sm sm:text-base"
                  >
                    Purchase or Access Plans
                  </a>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="flex-1 rounded-lg overflow-hidden backdrop-filter my-2 backdrop-blur-md bg-opacity-40 bg-black p-6">
          <h4 className="text-xl sm:text-2xl font-semibold mb-4">
            Alternative Way
          </h4>
          <p className="text-sm sm:text-base">
            Use FireFox and use Extension in one step{" "}
            <a
              href="https://addons.mozilla.org/en-US/firefox/addon/toolzstream-me/"
              className=" text-blue-400"
            >
              {" "}
              Link
            </a>
            .
          </p>
        </div>

        <div className="flex-1 rounded-lg overflow-hidden backdrop-filter backdrop-blur-md bg-opacity-40 bg-black p-6">
          <h4 className="text-xl sm:text-2xl font-semibold mb-4">
            Alternative Installation Help
          </h4>
          <p className="text-sm sm:text-base">
            If you face any issues during the installation, refer to our{" "}
            <Link to="/help" className="text-blue-400 underline">
              Help section
            </Link>{" "}
            for troubleshooting steps or contact our{" "}
            <Link to="/contact" className="text-blue-400 underline">
              support team
            </Link>{" "}
            for assistance.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Guide;
