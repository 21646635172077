import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useUser } from "../ContextApi/userContextApi.jsx";
import { SnackbarProvider, useSnackbar } from "notistack";
import axios from "axios";
import Loader from "../Pages/Loader.jsx";
import Customer from "./Custumer.js";
import Admin from "./Admin";
import Home from "../components/Home";
import Login from "../Auth/Login";
import Register from "../Auth/Register";
import NotFound from "../Pages/Notfound.jsx";
import Forget from "../Auth/Forget.jsx";
import About from "../Pages/About.jsx";
import PrivacyPolicy from "../Pages/PrivacyPolicy.jsx";
import HowToUse from "../Pages/howToUse.jsx";
import Notification from "../Pages/notification.jsx";
import Tns from "../Pages/tns.jsx";
import Disclaimer from "../Pages/disclaimer.jsx";
import ContactUs from "../Pages/contact.jsx";
import Help from "../Pages/help.jsx";

const RoutesWithNotification = ({ backendLoaded }) => {
  const { user } = useUser();




  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forget" element={<Forget />} />
      <Route path="/about" element={<About />} />
      <Route path="/how-to-use" element={<HowToUse />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/term-and-conditions" element={<Tns />} />
      <Route path="/disclaimer" element={<Disclaimer />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/help" element={<Help />} />
      <Route path="/notification" element={<Notification />} />
      <Route path="/customer/*" element={<Customer />} />

      {/* Protected Routes */}
      {user ? (
        <>
          {/* <Route path="/customer/*" element={<Customer />} /> */}
          <Route path="/admin/*" element={<Admin />} />
        </>
      ) : (
        <Route path="/customer/*" element={<Navigate to="/" />} />
      )}

      {/* Catch-all route for 404 */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const Routesroute = () => {
  const [backendLoaded, setBackendLoaded] = useState(false);

  useEffect(() => {
    const checkBackendConnection = async () => {
      try {
        // Check backend connection
        await axios.get(process.env.REACT_APP_BACKEND); // Replace with your actual backend root URL
        setBackendLoaded(true);
      } catch (error) {
        console.error("Error connecting to the backend:", error);
        // Handle the error accordingly
      }
    };

    checkBackendConnection();
  }, []);

  return (
    <SnackbarProvider maxSnack={3}>
      {backendLoaded ? (
        <RoutesWithNotification backendLoaded={backendLoaded} />
      ) : (
        <Loader />
      )}
    </SnackbarProvider>
  );
};

export default Routesroute;
